export const getWeekObj = {
    getNumberOfWeeks:undefined as any,
    getNumbersOfDays:undefined as any,
} 
export const servieModal = {
    enable:false as boolean
}
export function getBrandJsonValue(){
    if(!window.objOSGlobal.requestBodyValues.brandJsonGlobalObj) {
        window.objOSGlobal.requestBodyValues.Country = $("#countryCode").val() ?  $("#countryCode").val() : window.objOSGlobal.requestBodyValues.Country;
        var brands = window.objOSGlobal.requestBodyValues.Country.toUpperCase() == 'CA' ? "/ca/en-ca/_assets/brands.json" : "/us/en-us/_assets/brands.json";
        $.ajaxSetup({
            async: false
        });
        $.getJSON(brands, function (result:any) {
            if (result.length > 0) {
                var items = $.grep(result, function (e:any) { return e.code === $('#conceptCode').val()});
                window.objOSGlobal.requestBodyValues.brandJsonGlobalObj = items;
                if(items.length) {
                    if($('#conceptCode').val() == "MLY"){
                        var frequency = "";
                        if ($("#inputOneTime").is(":checked")){
                            frequency = "onetime";
                            $('.estimation-list-screen .fieldset-title').text('One-Time Cleaning Estimate');
                            $('.estimation-list-screen > p').text('Based on the information provided:');
                        }else{
                            frequency = "recurring";
                        }
                        var title = frequency == "recurring" ? items[0].schedule_title_recurring : items[0].schedule_title_onetime;
                        var subtitle = frequency == "recurring" ? items[0].schedule_subtitle_recurring : items[0].schedule_subtitle_onetime;
                        $('#step4 .calendar-screen .fieldset-title').text(title);
                        $('#step4 .calendar-screen .sub-heading').text(subtitle);
                        $('#step2.estimate-screen .fieldset-title').text(title);
                        $('#step2.estimate-screen .sub-heading').text(subtitle);
                    }
                    else if($('#conceptCode').val() == "MRA"){
                        $('#step3.calendar-screen .sub-heading').text(items[0].schedule_subtitle);
                    }
                    else{       
                        $('#step2 .calendar-screen .sub-heading').text(items[0].schedule_subtitle);
                    }
                    getWeekObj.getNumberOfWeeks = items[0]?.calendarMaxWeeksCap ? items[0].calendarMaxWeeksCap : undefined;	
                    if(getWeekObj.getNumberOfWeeks){
                        getWeekObj.getNumbersOfDays = getWeekObj.getNumberOfWeeks * 7;
                    }	
                    if(items[0]?.disableServiceSelection){
                        servieModal.enable = items[0]?.disableServiceSelection;
                        sessionStorage.setItem("ServiceModalEnable", items[0]?.disableServiceSelection);
                    }
                    localStorage.setItem("isTrailingSlash", items[0].includeTrailingSlash);
                }
            }
        });
    }else {
        let items = window.objOSGlobal.requestBodyValues.brandJsonGlobalObj
        if(items.length) {
            if($('#conceptCode').val() == "MLY"){
                var frequency = "";
                if ($("#inputOneTime").is(":checked")){
                    frequency = "onetime";
                    $('.estimation-list-screen .fieldset-title').text('One-Time Cleaning Estimate');
                    $('.estimation-list-screen > p').text('Based on the information provided:');
                }else{
                    frequency = "recurring";
                }
                var title = frequency == "recurring" ? items[0].schedule_title_recurring : items[0].schedule_title_onetime;
                var subtitle = frequency == "recurring" ? items[0].schedule_subtitle_recurring : items[0].schedule_subtitle_onetime;
                $('#step4 .calendar-screen .fieldset-title').text(title);
                $('#step4 .calendar-screen .sub-heading').text(subtitle);
                $('#step2.estimate-screen .fieldset-title').text(title);
                $('#step2.estimate-screen .sub-heading').text(subtitle);
            }
            else if($('#conceptCode').val() == "MRA"){
                $('#step3.calendar-screen .sub-heading').text(items[0].schedule_subtitle);
            }
            else{       
                $('#step2 .calendar-screen .sub-heading').text(items[0].schedule_subtitle);
            }
            getWeekObj.getNumberOfWeeks = items[0]?.calendarMaxWeeksCap ? items[0].calendarMaxWeeksCap : undefined;	
            if(getWeekObj.getNumberOfWeeks){
                getWeekObj.getNumbersOfDays = getWeekObj.getNumberOfWeeks * 7;
            }	
            if(items[0]?.disableServiceSelection){
                servieModal.enable = items[0]?.disableServiceSelection;
                sessionStorage.setItem("ServiceModalEnable", items[0]?.disableServiceSelection);
            }
            localStorage.setItem("isTrailingSlash", items[0].includeTrailingSlash);
        }
    }
}