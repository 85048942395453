import { apiConfig } from "../config/apiConfig";
import {$} from "../third-party-imports";


let getShelter:any={};
let statesData:any =[];
(function(){
    $('.text-danger.donationCheckbox').removeClass('invalid-feedback');
    $("label[for='checkbox1']").on('click',function(){
        $('.text-danger.donationCheckbox').hide();
        $('.custom-checkbox .donationCheckbox1').prop('checked', true);
        $('.custom-checkbox .donationCheckbox2').prop('checked', false);
    })
    $("label[for='checkbox2']").on('click',function(){
        $('.text-danger.donationCheckbox').hide();
        $('.custom-checkbox .donationCheckbox2').prop('checked', true);
        $('.custom-checkbox .donationCheckbox1').prop('checked', false);
    })
    let mlyConceptCode = $('#conceptCode').val() || $('#conceptId').val();
    if (window.location.pathname === '/about-us/ms-molly-foundation/donate/') {
    if(mlyConceptCode =='MLY' || mlyConceptCode ==1){
        getDonationAmounts();         // GetDonationAmounts API
        getShelters();               // GetShelters API
        donationGetState();         // state API
        donationFormSteps();
     }
   }
 }());
 
 function donationCommonAjaxCall(obj:any,successHandler:any){
     let url = obj.url;
     let type = obj.type;
     let data = JSON.stringify(obj?.payload)
     $.ajax({
       url,
       type,
       data,
       contentType: "application/json",
       dataType: 'json',
       success: function (res){
         successHandler(res);
       },
       error: function (error){  
       }
     });
 }
 
 function getDonationAmounts(){
     let url = `${apiConfig.coreApiBaseUrl}/MsMollyDonations/GetDonationAmounts`;
     let type = 'GET';
     let obj ={
         url,
         type
     }
     donationCommonAjaxCall(obj,function(results:any){
           if(results.length > 0){
             $('select[name="getDonationAmounts"]').empty();
             let donationAmount:any = [];
             $.each(results, function (index, amt) {
               donationAmount.push('<option value="' + amt.DonationAmount +'">$' + amt.DonationAmount + '</option>');
             });
             $('select[name="getDonationAmounts"]').append(donationAmount);
         }
     })
 }
 
 function getShelters(){
   let url = `${apiConfig.coreApiBaseUrl}/MsMollyDonations/GetShelters`;
   let type = 'GET';
   let obj ={
        url,
        type
   }
   donationCommonAjaxCall(obj,function(results:any){
         const shelterWiseSort = results.sort(sortingSheltersOrder);
         if(shelterWiseSort.length > 0){
             const filterWithState:any = [];
             const filterWithoutState:any = [];
             $('select[name="getShelters"]').empty();
             shelterWiseSort.map(function (el:any)
             {
                 if (!isEmptyCheck(el.State))
                 {
                     filterWithState.push(el);
                 } else
                 {
                     filterWithoutState.push(el);
                 }
             });
           
            const stateWiseShelter = filterWithState.sort(sortingSheltersStateOrder);
            const nonStateWiseShelter = filterWithoutState.sort(sortingSheltersOrder);
            const sortedData = stateWiseShelter.concat(nonStateWiseShelter);
            getShelter.list = sortedData;
       }
   });
 }
   
 function donationGetState(){
        let type = 'GET';
        let baseUrl = apiConfig.stateApi;
        let country = "";
        let domainName = location.hostname.toLowerCase();  
        let domainType = window.DEFINE_VALUE.Domain_Type;
    if (domainType != "digital"){
            let languageTrans = window.location.href;
            let slug = languageTrans.split('/');
            country = slug[3];
        }
        else{
            country = domainName.indexOf("-ca-") === -1 && domainName.indexOf(".ca") === -1 && domainName.indexOf("-ca.") ===-1 ? "us" : "ca";
        }
        if (country == 'ca'){
            let caUrl = baseUrl + "CA?apikey=" + window.DEFINE_VALUE.JS_API_KEY;
            let caObj ={
                caUrl,
                type
            }
            $('select[name="donationState"]').empty();
            donationCommonAjaxCall(caObj,function(results:any){
                let usUrl = baseUrl + "US?apikey=" + window.DEFINE_VALUE.JS_API_KEY;
                let usObj ={
                    usUrl,
                    type
                }
                let states:any = [];
                states.push('<option value="" selected="selected">- Select State -</option>');
                $.each(results, function (index, st) {
                    states.push('<option value="' + st.stateCode +'">' + st.stateName + '</option>');
                    statesData.push({stateCode:st.stateCode,stateName:st.stateName});
                 });
                donationCommonAjaxCall(usObj,function(results:any){
                     $.each(results, function (index, st) {
                        states.push('<option value="' + st.stateCode +'">' + st.stateName + '</option>');
                     });
                    $('select[name="donationState"]').empty();
                    $('select[name="donationState"]').append(states);
                });
                states.push('<option value="" selected="selected">- Select State -</option>')
                $.each(results, function (index, st) {
                states.push('<option value="' + st.stateCode +'">' + st.stateName + '</option>');
                statesData.push({stateCode:st.stateCode,stateName:st.stateName});
                });
                $('select[name="donationState"]').append(states);
            });
        }else{
            let countryCode = "US";
            let states:any = [];
            let url =  baseUrl + countryCode + "?apikey=" + window.DEFINE_VALUE.JS_API_KEY;
            let obj  = {
                url,
                type
            }
            donationCommonAjaxCall(obj,function(results:any){
                states.push('<option value="" selected="selected">- Select State -</option>');
                $.each(results, function (index, st) {
                   states.push('<option value="' + st.stateCode +'">' + st.stateName + '</option>');
                   statesData.push({stateCode:st.stateCode,stateName:st.stateName});
                });
               $('select[name="donationState"]').empty();
               $('select[name="donationState"]').append(states);
           });
        }
 }

 $('.js-next-donation-btn-pay').on('click',function(){
        if (!($('#donationformstep2') as any).valid()){
            return;
        }   
        let msMollyShelter:any = getValueFilterVal();
        let donationAmount:any = $('select[name="getDonationAmounts"] option:selected').val();
        let donorFirstName = $('input[name="donationFirstname"]').val();
        let donorLastName = $('input[name="donationLastname"]').val();
        let donorEmail = $('input[name="donationEmail"]').val();
        let donationPhoneType =  $('select[name="donationPhoneType"] option:selected').val();
        let donorAddress = $('input[name="donationAddress1"]').val();
        let donorCity = $('input[name="donationCity"]').val();
        let donorState = $('select[name="donationState"] option:selected').val();
        let donorpostalCode = $('input[name="donationPostalCode"]').val();
        let comments = $('textarea[name="donation-queries"]').val();
        let isCurrentCustomer = $('input[type=checkbox][name=donationCheckbox]:checked').val() == 'yes' ? true:false;
        let IsMobile = donationPhoneType =='Mobile' ? true :false;
        let campaign:any = null;

        let url = `${apiConfig.coreApiBaseUrl}/MsMollyDonations/PrePurchase`;
        let type ='POST'
        donationAmount = parseInt(donationAmount);
        msMollyShelter = msMollyShelter =='where the need is greatest'? null : parseInt(msMollyShelter);
        
        let payload = {
            "Amount": donationAmount,
            "MsMollyShelterId": msMollyShelter,
            "DonorFirstName": donorFirstName,
            "DonorLastName": donorLastName,
            "DonorEmail": donorEmail,
            "DonorPhoneType": donationPhoneType,
            "DonorAddress": donorAddress,
            "DonorCity": donorCity,
            "DonorState": donorState,
            "DonorPostalCode": donorpostalCode,
            "IsCurrentCustomer": isCurrentCustomer,
            "Comments": comments,
            "IsMobile": IsMobile,
            "Campaign":campaign,
        }
        let obj ={
            url,
            type,
            payload
        }
        donationCommonAjaxCall(obj,function(results:any){
        let invoicenumber = results.MsMollyDonationId;
        let merchantKey = window.DEFINE_VALUE.JS_DONATION_MERCHANT_KEY || '884d8bd0-3f8d-45f2-80b8-fc2d07fc2afc';
        let url = `${apiConfig.paymentPostUrl}?invoicenumber=${invoicenumber}&appkey=${merchantKey}`;
        $("#donationformstep2").attr('action', url);
        $("#donationformstep2").attr('method', 'POST');
        let formSubmit:any = document.getElementById("donationformstep2");
        if(formSubmit){
          formSubmit.submit();
        }
    })
 })



 function sortingSheltersOrder(a:any,b:any){
    const bandA = a.Name.toUpperCase();
    const bandB = b.Name.toUpperCase();
    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
};
function sortingSheltersStateOrder(a:any,b:any){
    const bandA = a.State.toUpperCase();
    const bandB = b.State.toUpperCase();
    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
};
$('textarea[name="donation-queries"]').keyup(function(){
    textAreaValidate();
    
})

function textAreaValidate(){
    let isVal = true;
    let val:any = $('textarea[name="donation-queries"]').val();
    let erro  = '<div id="donationMessage-error" class="error help-block invalid-feedback">Please do not enter more than 500 characters</div>';
    if(val.length > 500) {
        $('#donationMessage-error').remove();
        $('textarea[name="donation-queries"]').parent().append(erro);
        $('#donation-sunbit-btn').prop("disabled", true);
        isVal = false;
    }else{
        $('#donationMessage-error').remove();
        $('#donation-sunbit-btn').prop("disabled", false);
        isVal = true;
    }
    return isVal;
}


function donationFormSteps(){
    $(".js-next-donation-btn").click(function (this: any)
    {
        if (!($('#donation-form') as any).valid()){
            return;
        }
        let nextId = $(this).parents(".tab-pane").next().attr("id");
        if(nextId ==="step2"){
            let isMollyMaidCustomer = $('input[name="currently-radio"]:checked');
            donationDefaultCall();
            if(isMollyMaidCustomer?.val().toLowerCase()==="yes"){
                const id  = $('input[type=radio][name=shelter-mm-yes-radio]:checked').attr('id');
                mlyShelterYes(id);
                $('.mm-customer-no').addClass("d-none");
                $('.mm-customer-yes').removeClass("d-none");
            }else{
                const id  = $('input[type=radio][name=shelter-mm-no-radio]:checked').attr('id');
                mlyShelterNo(id);
                $('.mm-customer-yes').addClass("d-none");
                $('.mm-customer-no').removeClass('d-none');
            }
        }
        $('.tab-pane.fieldset').removeClass('active');
        $('#' + nextId).addClass('active');
    });
    $(".js-previous-donation-btn").click(function (this: any)
    {
        let prevId = $(this).parents(".tab-pane").prev().attr("id");
        $('.tab-pane.fieldset').removeClass('active');
        $('#' + prevId).addClass('active');
    });
}


function donationDefaultCall(){
    $('.mm-yes-shel').change(function (this: any)
    {
        resetSelectShelterVal();
        let id = $(this).attr('id');
        mlyShelterYes(id);
    });

    $('.mm-no-shel').change(function (this: any)
    {
        resetSelectShelterVal();
        let id = $(this).attr('id');
        mlyShelterNo(id);
    });
}


function mlyShelterYes(id:any){
    if (id === 'mm-yes-shelter-local')
    {
        $(".mm-customer-yes .state-select-box").addClass("d-none");
        $(".mm-customer-yes .shelters-select-box").addClass("d-none");
    }
    else if (id === 'mm-yes-shelter-state')
    {
        $(".mm-customer-yes .shelters-select-box").addClass("d-none");
        $(".mm-customer-yes .state-select-box").removeClass("d-none");
        let donorStateVal = $('select[name="donationState"] option:selected').val();
        sheleterListUpdate(donorStateVal, "donationstatewidelist");
    }
    else if (id === 'mm-yes-shelter-browser')
    {
        $(".mm-customer-yes .state-select-box").addClass("d-none");
        $(".mm-customer-yes .shelters-select-box").removeClass("d-none");
        sheleterListUpdate("", "donationnationwidelist");
    }
}

function mlyShelterNo(id:any){
    if (id === 'shelter-mm-no-browser')
    {
        $(".mm-customer-no .state-select-box").addClass("d-none");
        $(".mm-customer-no .shelters-select-box").removeClass("d-none");
        sheleterListUpdate("", "comnationwide");
    }
    else if (id === "shelter-mm-no-state")
    {
        $(".mm-customer-no .shelters-select-box").addClass("d-none");
        $(".mm-customer-no .state-select-box").removeClass("d-none");
        let donorStateVal = $('select[name="donationState"] option:selected').val();
        sheleterListUpdate(donorStateVal, "comStatewide");
    }
    else if (id === 'shelter-mm-no-distribute')
    {
        $(".mm-customer-no .state-select-box").addClass("d-none");
        $(".mm-customer-no .shelters-select-box").addClass("d-none");
    }
}
function sheleterListUpdate(donorStateVal:any,selectName:any){
    let shelters:any = [];
    let mollyShelter:any = getShelter.list;
    if(donorStateVal!==""){
        mollyShelter = mollyShelter.filter((shelter:any)=> shelter.State===donorStateVal);
        if(mollyShelter){
            mollyShelter.map((shelterVal:any)=>{
                shelters.push('<option value="' + shelterVal.MsMollyShelterId +'">' + shelterVal.Name + ' - '+shelterVal.City+ ', '+shelterVal.State+'</option>');
            });
        }
    }else{
        groupSameValue(mollyShelter,shelters);
    }
    $(`select[name="${selectName}"] option:not(:first)`).remove();
    $(`select[name="${selectName}"]`).append(shelters);
}


function resetSelectShelterVal(){
    $("select[name='donationnationwidelist']").val("").trigger( "change" );
    $("select[name='donationstatewidelist']").val("").trigger( "change" );
    $("select[name='comnationwide']").val("").trigger( "change" );
    $("select[name='comStatewide']").val("").trigger( "change" );
}

function getValueFilterVal(){
    const donationnationwidelist = $("select[name='donationnationwidelist']").find(":selected").val();
    const donationstatewidelist = $("select[name='donationstatewidelist']").find(":selected").val();
    const comnationwide = $("select[name='comnationwide']").find(":selected").val();
    const comStatewide = $("select[name='comStatewide']").find(":selected").val();
    let shelterVal:any;
    if(donationnationwidelist !==""){
        shelterVal = donationnationwidelist;
    }
    else if(donationstatewidelist !==""){
        shelterVal = donationstatewidelist;
    }
    else if(comnationwide !==""){
        shelterVal = comnationwide;
    }
    else if(comStatewide !==""){
        shelterVal = comStatewide;
    }
    return shelterVal;
}


function isEmptyCheck(val:any){
    return (val === undefined || val == null || val.length <= 0) ? true : false;
}

function groupSameValue(mollyShelter: any, shelters: any)
{
    
    let duplicateState:any = [];
    const result = Object.groupBy(mollyShelter, ({ State }) => State);
    for (let key in result)
    {
        if (result[key].length > 0)
        {
            result[key].map((shelterVal: any) =>
            {
                if(!isEmptyCheck(shelterVal.State)){
                    getStateName(shelterVal.State,shelters,duplicateState);
                }
                shelters.push('<option value="' + shelterVal.MsMollyShelterId + '">' + shelterVal.Name + ' - ' + shelterVal.City + ', ' + shelterVal.State + '</option>');
            })
        }
    }
}
function getStateName(stCode:any,shelters:any,duplicateState:any){
    if(!isInArray(stCode.toUpperCase(),duplicateState)){
        statesData.map((st: any) =>
        {
            if (stCode.toUpperCase() === st.stateCode.toUpperCase())
            {
                duplicateState.push(st.stateCode.toUpperCase());
                shelters.push('<option disabled>--' + st.stateName + '--</option>');
            }
        });
    }
}

function isInArray(value:any, array:any) {
    return array.indexOf(value) > -1;
}