// Code goes here
import { LeadConfirmation } from "../../services/LeadConfirmation";
import { $ } from "../../third-party-imports";
import { checkZipLocalSite} from "../../util";
import { schedulerState } from "../scheduler";
import { serviceDetails, storeAllfieldsValue } from "./store-value-os-flow";


export function optimizeOsCommonFLow()
{
    $(".btn-row button").on('click', function ()
    {
        $(".modal").animate({ scrollTop: 0 }, "300");
    });

    $('.js-next-btn').off('click').on('click',async function (this:any)
    {

        let $validate: any = validate_the_step($(this));

        if (!$validate)
        {
            return;
        }

        let nextId = $(this).parents('.tab-pane').next().attr("id");
        if(nextId ==='stepone_change_location_local'){
            nextId ='step1';
        }
        if(nextId ==='step2'){
            if($('#conceptCode').val()==='MRR'){
                $('#mrr-emergency-box').removeClass('d-flex');
            }
            window.objOSGlobal.paramsUI.selectedDate = schedulerState.selectedDate;
        }
        if(nextId==='step3'){
            preferredMethodOfContact();
            var changeZip = sessionStorage.getItem("changeZip");
            if (changeZip) {
                storeAllfieldsValue($(this));
                checkZipLocalSite(nextId);
                return;
            }
            if(!validTextMethodChk()){
                return;
            }
        }
        
        storeAllfieldsValue($(this));
       
        if (nextId == "hdnSD_NoResultsMsg") {
            serviceDetails();
            LeadConfirmation();
              
        }
        $('.scheduler-modal .modal-content').removeClass('banner-wizard');
        $('[href="#' + nextId + '"]').tab('show');
        return false;

    });


    $('.js-previous-btn').off('click').on('click',function (this: any)
    {
        let isLead:any  = window.objOSGlobal.requestBodyValues.IsLeadOnly;
        let prevId = $(this).parents('.tab-pane').prev().attr("id");
        if(prevId ==="stepone_change_location_local"){
            let countryCode = $("#countryCode").val();
            let conceptCode = $("#conceptCode").val();
            $('.tab-pane.fieldset').removeClass('active');
            $('.scheduler-modal .modal-content').addClass('banner-wizard');
            $('.progress, .progressbar').hide();
            $('.schedulerModalLabel').empty();
            if(conceptCode ==="MRR" && countryCode==="ca"){
                if(window.objOSGlobal.requestBodyValues.fsaLead){
                    let schedule = localStorage.getItem('home-scheduler-btn');
                    if(schedule == 'true'){
                        localStorage.removeItem('home-scheduler-btn');
                        $('#schedulerWizard button.close').trigger('click');
                    }else{
                        $('.zip-entry-screen').addClass('active');
                    }
                }else{
                    $('#step-location').addClass('active');
                }
            }else{
                $('.zip-entry-screen').addClass('active');
            }
            return;
        }
        $('.scheduler-modal .modal-content').removeClass('banner-wizard');
        if(isLead){
            if(prevId =='step1'){
                let countryCode = $("#countryCode").val();
                let conceptCode = $("#conceptCode").val();
                $('.tab-pane.fieldset').removeClass('active');
                $('.scheduler-modal .modal-content').addClass('banner-wizard');
                $('.progress, .progressbar').hide();
                $('.schedulerModalLabel').empty();
                if(conceptCode ==="MRR" && countryCode==="ca"){
                    if(window.objOSGlobal.requestBodyValues.fsaLead){
                        let schedule = localStorage.getItem('home-scheduler-btn');
                        if(schedule == 'true'){
                            localStorage.removeItem('home-scheduler-btn');
                            $('#schedulerWizard button.close').trigger('click');
                        }else{
                            $('.zip-entry-screen').addClass('active');
                        }
                    }else{
                        $('#step-location').addClass('active');
                    }
                }else{
                    $('.zip-entry-screen').addClass('active');
                }
            }else{
                $('.scheduler-modal .modal-content').removeClass('banner-wizard');
                $('[href="#' + prevId + '"]').tab('show');
            }
        }else{
            if(prevId ==='step1'){
                if($('#conceptCode').val()==='MRR'){
                    $('#mrr-emergency-box').addClass('d-flex');
                }
            }
            $('[href="#' + prevId + '"]').tab('show');
        }
 
        return false;
    });


    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e:any)
    {
        //update progress
        let step = $(e.target).data('step');
        let percent:any = ((parseInt(step) / 3) * 100).toFixed(2);
        let isLead:any  = window.objOSGlobal.requestBodyValues.IsLeadOnly;

        switch (step) {
            case 1:
                percent = 4.2;
                $('.progress-bar').css({ width: percent + '%' });
                break;
            case 2:
                percent = isLead ? 4.2 : 53;
                $('.progress-bar').css({ width: percent + '%' });
                break;
            case 3:
                percent = 100;
                $('.progress-bar').css({ width: percent + '%' });
                break;
        }
    })
}



export function validate_the_step($button: any)
{

    var $current_step = $button.parents('.tab-pane');

    var $valid = true;

    let $required_fields = $current_step.find('[required]');

    if ($current_step.find('.calendar-wrapper').length)
    {

        let $active = $current_step.find('.calendar-col .active');

        if ($active.length)
        {
            $valid = true;
            $current_step.find('.dt-error').addClass('d-none');
        }
        else
        {
            $valid = false;
            $current_step.find('.dt-error').removeClass('d-none');
        }
    }


    if ($required_fields.length)
    {
        $required_fields.each(function (i: any, $element: any)
        {

            let $field = $($element);
            let $name = $field.attr('name');

            if ($field.is(':visible'))
            {

                if ($field.is(':radio') || $field.is(':checkbox'))
                {
                    var $checked = $current_step.find('input[name="' + $name + '"]:checked');
                    console.log($checked.length);
                    if ($checked.length === 0)
                    {
                        $valid = false;
                        $field.parent().parent().addClass('has-error');
                        $field.parent().parent().find('.error-msg').show();
                        $button.parents('.tab-pane').addClass('step-has-error');
                    }
                    else
                    {
                        $field.parent().parent().removeClass('has-error');
                        $field.parent().parent().find('.error-msg').hide();
                    }
                }
                else
                {
                    if (typeof $field.val() == 'undefined' || !$field.val() || $field.val() == '')
                    {
                        $valid = false;
                        $field.parent().parent().find('.error-msg').show();
                        $field.parent('.form-group').addClass('msg-show');
                    }
                    else
                    {
                        if ($name == "inputEmail")
                        {
                            if (checkEmail($field.val()))
                            {
                                $field.parent().parent().find('.error-msg').show();
                                $field.parent().parent().find('.error-msg').text('Please check email address is invalid.');
                                $field.parent('.form-group').addClass('msg-show');
                                $valid = false;
                            } else
                            {
                                $field.parent().parent().find('.error-msg').hide();
                                $field.parent('.form-group').removeClass('msg-show');
                            }
                        }
                        else if ($name == "inputPhnNumber")
                        {
                            if (checkPhoneNumber($field.val()))
                            {
                                $field.parent().parent().find('.error-msg').show();
                                $field.parent().parent().find('.error-msg').text('Please enter a valid phone number');
                                $field.parent('.form-group').addClass('msg-show');
                                $valid = false;
                            } else
                            {
                                $field.parent().parent().find('.error-msg').hide();
                                $field.parent('.form-group').removeClass('msg-show');
                            }
                        }
                        else if ($name == "inputZipCode")
                        {
                            if (checkZipPostalCode($field.val()))
                            {
                                $field.parent().parent().find('.error-msg').show();
                                if(isValidCAPostal($field.val())){
                                    $field.parent().parent().find('.error-msg').text('Invalid Postal Code');
                                }else{
                                    $field.parent().parent().find('.error-msg').text('Invalid Zip Code');
                                }
                                
                                $field.parent('.form-group').addClass('msg-show');
                                $valid = false;
                            } else
                            {
                                $field.parent().parent().find('.error-msg').hide();
                                $field.parent('.form-group').removeClass('msg-show');
                            }
                        }
                        else if ($name == "inputAddress")
                        {
                            if (propStreetAddressChecker($field.val()))
                            {
                                $field.parent().parent().find('.error-msg').show();
                                $field.parent().parent().find('.error-msg').text('Please enter valid street address');
                                $field.parent('.form-group').addClass('msg-show');
                                $valid = false;
                            } else
                            {
                                $field.parent().parent().find('.error-msg').hide();
                                $field.parent('.form-group').removeClass('msg-show');
                            }
                        }
                        else if ($name == 'inputcity')
                        {
                            if (checkCity($field.val()))
                            {
                                $field.parent().parent().find('.error-msg').show();
                                $field.parent().parent().find('.error-msg').text('Please enter character only');
                                $field.parent('.form-group').addClass('msg-show');
                                $valid = false;
                            } else
                            {
                                $field.parent().parent().find('.error-msg').hide();
                                $field.parent('.form-group').removeClass('msg-show');
                            }
                        }
                        else if ($name == "inputFirstName")
                        {
                            if (checkFistLastName($field.val()))
                            {
                                $field.parent().parent().find('.error-msg').show();
                                $field.parent().parent().find('.error-msg').text('Please enter letters and spaces Only');
                                $field.parent('.form-group').addClass('msg-show');
                                $valid = false;
                            } else
                            {
                                $field.parent().parent().find('.error-msg').hide();
                                $field.parent('.form-group').removeClass('msg-show');
                            }
                        }
                        else if ($name == "inputLastName")
                        {
                            if (checkFistLastName($field.val()))
                            {
                                $field.parent().parent().find('.error-msg').show();
                                $field.parent().parent().find('.error-msg').text('Please enter letters and spaces Only');
                                $field.parent('.form-group').addClass('msg-show');
                                $valid = false;
                            } else
                            {
                                $field.parent().parent().find('.error-msg').hide();
                                $field.parent('.form-group').removeClass('msg-show');
                            }
                        }
                        else
                        {
                            $field.parent().parent().find('.error-msg').hide();
                            $field.parent('.form-group').removeClass('msg-show');
                        }
                    }
                }
            }

        });
    }

    return $valid;
}


function checkEmail(email: any)
{
    let filter = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!filter.test(email))
    {
        return true;
    }
}

function checkPhoneNumber(phone: any)
{
    if ((phone.length < 14))
    {
        return true;
    }
}

function checkZipPostalCode(zip: any){
    if(isValidCAPostal(zip)){
        let filter =  /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
        if($("#conceptCode").val() === 'MRR' && $("#countryCode").val() === 'ca'){
            if(window.objOSGlobal.requestBodyValues.isRequestForm){
                filter =  /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
            }else{
                // if (localStorage.getItem('zipcode').toUpperCase() !=  zip.toUpperCase()){
                //     filter =  /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
                // }else{
                //     filter =  /^[A-Za-z]\d[A-Za-z]/;
                // }
                filter =  /^[A-Za-z]\d[A-Za-z]/;
            }
        }
        if (!filter.test(zip)){
            return true;
        }
        
    }else{
        let filter =  /(^\d{5}$)|(^\d{5}-\d{4}$)/;
        if (!filter.test(zip)){
            return true;
        }
    }
}

function checkCity(city: any)
{
    let filter = /^[a-z][a-z\s]*$/i;
    if (!filter.test(city))
    {
        return true;
    }
}

function checkFistLastName(name: any)
{
    let filter =  /^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/gim;
    if (!filter.test(name))
    {
        return true;
    }
}



function propStreetAddressChecker(street: any)
{
    let filter = /[~`!@#$%\^&*()+=\\[\]\\';/{}|\\":<>\?]/;
    if (filter.test(street))
    {
        return true;
    }
}

export function isValidCAPostal(pcVal:any) {
    if($("#conceptCode").val() === 'MRR' && $("#countryCode").val() === 'ca'){
        if(window.objOSGlobal.requestBodyValues.isRequestForm){
            return /[A-Za-z][0-9][A-Za-z]\s{0,1}[0-9][A-Za-z][0-9]$/.test(pcVal);
        }else{
            // if (localStorage.getItem('zipcode').toUpperCase() != pcVal.toUpperCase()){
            //     return /[A-Za-z][0-9][A-Za-z]\s{0,1}[0-9][A-Za-z][0-9]$/.test(pcVal);
            // }else{
            //     return /^[A-Za-z]\d[A-Za-z]/.test(pcVal);
            // }
            return /^[A-Za-z]\d[A-Za-z]/.test(pcVal);
        }
    }else{
        return /[A-Za-z][0-9][A-Za-z]\s{0,1}[0-9][A-Za-z][0-9]$/.test(pcVal);
    }
    
}


export function preferredMethodOfContact(){
    try{
    if(window.brandData?.add_preffered_method_of_contact ){
        const obj = {
            "Text":2,
            "Phone":1,
            "Call": 1,
            "Email":3
        }
        let inputElems = document.querySelectorAll(".preferred-method .custom-checkbox input");
        let preferMethodText:any = [];
        let preferMethodNums:any = [];
        if(inputElems){
            inputElems.forEach((el:any)=>{
                if (el.type == "checkbox" && el.checked == true){
                    preferMethodText.push(el.labels[0].innerText);
                    preferMethodNums.push(obj[el.labels[0].innerText.trim()]);
                }
            });
            sessionStorage.setItem('PreferredCommunicationText',preferMethodText.toString());
            window.objOSGlobal.requestBodyValues.PreferredCommunicationType = preferMethodNums.toString();
            window.objOSGlobal.requestBodyValues.Note = window.objOSGlobal.requestBodyValues.Note.concat("\r\nPreferred Method of Contact: " + preferMethodNums.toString() + "\r\n");   
        }
    }
 } catch(error) {
    console.log("not found add_preffered_method_of_contact", error)
 }
}
export function validTextMethodChk() {
    try{
    if(window.brandData?.add_preffered_method_of_contact ){
        let mobChkElem:any = $('#chkMobile').length>0 ? $('#chkMobile'): $('#mobile-phone-check').length>0 ? $('#mobile-phone-check') : $('#mobile-phone-check2') ;
        let textElem:any = $('#preferText').length>0 ? $('#preferText'): $('#text-phone-check');
        let flag = true;
        if(textElem){
            if(textElem.is(":checked") === true && mobChkElem.is(":checked") === false){
                $('.prefcontact_error').removeClass("d-none");
                mobChkElem.parent().parent().addClass('msg-show');
                flag = false;
            }
            else {
                $('.prefcontact_error').addClass("d-none");
                mobChkElem.parent().parent().removeClass('msg-show');
            }
            
        }
        return flag;
    }
    return true;
  } catch(error) {
    console.log("not found add_preffered_method_of_contact", error)
 }
}

if(document.getElementById('text-phone-check') || document.getElementById('preferText')){

    $('#text-phone-check').click(function(){
        validTextMethodChk();
    });

    $('#preferText').click(function(){
        validTextMethodChk();
    });

    $('#chkMobile').click(function(){
        validTextMethodChk();
    });

    $('#mobile-phone-check').click(function(){
        validTextMethodChk();
    });
    
    $('#mobile-phone-check2').click(function(){
        validTextMethodChk();
    });
}



