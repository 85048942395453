import {$} from "../third-party-imports";
import { startLoader, stopLoader } from "./";
// import {apiConfig} from "../config/apiConfig";
var pageReviewNumber = 1;// temp_fx
var pageReviewSize =20; //temp_fx
var brandReviewUrl:any;
$(function(){
    if($('#review').hasClass('review-section')) {   
        startLoader();
    }
});

$(document).ready(function() {
    $(document).on('click', '.reviews-pagination-section .pagination-item', function(event:any) {
        event.preventDefault();
        const pageNumber = parseInt(event.target.id.split('-')[1]);
        if(!pageNumber)
        return
        pageReviewNumber = pageNumber;
        startLoader();
        updateReviewUrl(); 
        $("html, body").animate({ scrollTop: 0 }, "slow");
    });

    $(document).on('click', '.reviews-pagination-section #pagination-next', function(event:any) {
        event.preventDefault();
        pageReviewNumber++;
        startLoader();
        updateReviewUrl();
        $("html, body").animate({ scrollTop: 0 }, "slow");
    });

    $(document).on('click', '.reviews-pagination-section  #pagination-back', function(event:any) {
        event.preventDefault();
        pageReviewNumber = Math.max(1, pageReviewNumber - 1);
        startLoader();
        updateReviewUrl();
        $(".review-section").animate({ scrollTop: 0 }, "slow");
    });
});

$(window).on("load", function() {
    if($('#review').hasClass('review-section')) {        
        $('.review-section .review-container').remove();
        $('.review-section').show();        
        $('.reviews-pagination-section').show();
        startLoader();
        updateReviewUrl();    
    }
});

function updateReviewUrl(){
    let minRating;
    var concepId = $('#conceptId').val();
    var webLocationId = $("#weblocationId").val();
    let country = $("#countryCode").val();
    let countryId = ($("#countryCode").val() === 'us') ? 1 : 2 ;
    var brands =  `/${country}/en-${country}/_assets/brands.json`;
    $.getJSON(brands, function (result: any)
    {        
        if (result.length > 0)
        {
            let brandJsonObj = result.find((item: any) => item.id === concepId);
            minRating = brandJsonObj ? brandJsonObj.minimumReviewRating : "";
            var apiDomain = (window.location.hostname.indexOf("nblydev") != -1 || window.location.hostname.indexOf("nblysbx") != -1) ? "https://api2-test-unifiedsyncplatform.dwyergroup.com" : "https://api2-unifiedsyncplatform.dwyergroup.com";
            var broadlyReviewApiUrl = `${apiDomain}/public/UnifiedSyncPlatform/Reviews/review-aggregates/search?ConceptId=`;
            var apiKey = (window.location.hostname.indexOf("nblydev") != -1 || window.location.hostname.indexOf("nblysbx") != -1) ? 'tneiweb-L0WLDH5bGqSGWEy' : 'pneiweb-9pbaOywNKQj853D';

            if (webLocationId && concepId)
            {
                brandReviewUrl = broadlyReviewApiUrl + concepId + "&FranchiseWebLocationId=" + webLocationId + "&PageNumber=" + pageReviewNumber + "&PageSize=" + pageReviewSize + "&CountryId=" + countryId + "&MinimumRating=" + minRating + "&apikey=" + apiKey;
                broadlyReviewApiCall(brandReviewUrl);
            } else
            {
                brandReviewUrl = broadlyReviewApiUrl + concepId + "&PageNumber=" + pageReviewNumber + "&PageSize=" + pageReviewSize + "&CountryId=" + countryId + "&MinimumRating=" + minRating + "&apikey=" + apiKey;
                broadlyReviewApiCall(brandReviewUrl);
            }
        } else {
                $('.review-section .reviews-pagination-section').addClass('d-none');
        }
    });
}

function broadlyReviewApiCall(brandUrl:any){
        $('.review-section .review-container').remove();
        $.ajax({
            url: brandUrl,
            type: "GET",
            dataType: "json",
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json'
            },
            async:false,
            success: function(data:any){
                stopLoader();
                var reviewContainer:any =[];
                var paginationComponent = getPaginationComponent(data?.length?Number(data[0]?.grandTotal||data.length):0)
                // let new_FilteredArray:any = data?.filter(function(e:any) {
                //     return e.reviewStarRating >= 4
                // });
                $.each(data, function( index:any, element:any ){
                    for(var i in element) {
                        if(!element[i]) {
                         element[i] = '';
                        }
                     }
                    reviewContainer.push(generateReviewContainer(element));
                });
                // if(data[0].grandTotal>10){
                //     reviewPagination(data[0].grandTotal);
                //     $('.review-section .reviews-pagination-section').removeClass('d-none');
                // }else{
                //     $('.review-section .reviews-pagination-section').addClass('d-none');
                // }
                // $('.total-review strong').text(data[0].grandTotal);
                // $('.review-section .reviews-pagination-section').before(reviewContainer);
                //temp_fx
                
                $('.review-section .reviews-pagination-section').removeClass('d-none');
                $('.review-section').show();
                $('.review-section .reviews-pagination-section nav[aria-label="pagination"]').hide();
                $('.total-review strong').text(data.length);
                $('.review-section .reviews-pagination-section').before(reviewContainer);
                $('.reviews-pagination-section .pagination-wrapper').html(paginationComponent)
              },
              error: function (xhr:any, status:any, error:any){
                stopLoader();
                $('.review-section .reviews-pagination-section').addClass('d-none');
              }
        })
}
function getPaginationComponent(count:any) {
    if (!count) return '';

    const totalPages = Math.ceil(count / pageReviewSize);
    const numPagesToShow = 5; // Number of pages to display before and after ellipses
    const currentPage = pageReviewNumber;

    let arrPageNumbers = '';

    function generatePageNumbers(start:any, end:any) {
        for (let i = start; i <= end; i++) {
            if (i === currentPage) {
                arrPageNumbers += `
                <li><a href="#"  class="pagination-item current" aria-current="page"><span class="sr-only">page</span>${i}</a></li>`;
            } else {
                arrPageNumbers += `
                <li><a class="pagination-item" id="${'page-'+i}" href="#"><span class="sr-only">page</span>${i}</a></li>`;
            }
        }
    }

    if (totalPages <= numPagesToShow) {
        // Display all pages if total pages are less than or equal to numPagesToShow
        generatePageNumbers(1, totalPages);
    } else {
        let start = 1, end = numPagesToShow;
        let middlePage = Math.floor(numPagesToShow / 2) + 1; // Selected Middle page number
        if (currentPage <= middlePage) {
            start = 1;
            end = numPagesToShow;
        } else if (currentPage >= totalPages - middlePage) {
            start = totalPages - numPagesToShow + 1;
            end = totalPages;
        } else {
            start = currentPage - Math.floor(numPagesToShow / 2);
            end = currentPage + Math.floor(numPagesToShow / 2);
        }
        generatePageNumbers(start, end);
        if(end !=  totalPages ){
         arrPageNumbers += (end != totalPages - 2)?` <li class="ellipsis">[...]</li> `:'';
        generatePageNumbers((end>=totalPages - 2) ? end+1 : totalPages - 2, totalPages);
       }
    }

    return `
        <div class="row">
            <div class="col-md-6">
                <nav aria-label="pagination">
                    <ul class="pagination text-sm">
                        ${pageReviewNumber === 1 ?
                           '<li><a href="#" id="pagination-back" class="pagination-item disabled first" aria-disabled="true" aria-label="Previous page">Back</a></li>' 
                         : `<li><a href="#" id="pagination-back" class="pagination-item  first" aria-disabled="false" aria-label="Previous page">Back</a></li>`}
                        ${arrPageNumbers}
                        ${pageReviewNumber != totalPages ?
                                `<li><a  id="pagination-next" class="pagination-item last" href="#" aria-disabled="false" aria-label="Next page">Next</a></li>`
                              : '<li><a  id="pagination-next" class="pagination-item last disabled"   href="#" aria-disabled="true" aria-label="Next page">Next</a></li>' }
                    </ul>
                </nav>
            </div>
            <div class="col-md-6 body-text-sm dark-grey-primary-clr total-review">
                <p>Total Number of Reviews:<strong class="secondary-theme-clr text-sm">${count}</strong></p>
            </div>
        </div>
    `;
}

function generateReviewContainer(element:any){
    return `<div class="review-container">
    <div class="container" aria-describedby="review">
       <div class="user-avatar"> <img src="${element.reviewPhotoUrl ? element.reviewPhotoUrl :'/us/en-us/brand/_assets/images/user-icon-m.svg'}" class="img-fluid" width="48" height="48" alt="${element.reviewAuthorName}" fetchpriority="low" loading="lazy"></div>
       <div class="user-review">
           <div class="reviewer-info-wrap">
                <five-star-display rating="${element.reviewStarRating}">
                </five-star-display>
                <strong class="text-lg">${element.reviewAuthorName}</strong>    
                ${element.reviewSourceType?` <strong class="text-sm review-source" >via ${element.reviewSourceType} </strong>  `:''} 
          </div>
          <p class="dark-grey-primary-clr">${element.reviewBody}</p>
          ${element.reviewResponseBody ?
            `<div class="reviewer-info-wrap review-response mobile-left-align dark-grey-primary-clr">
                    <h5 class="response-party">Response from <span class="response-party-author">${element.reviewResponseName}</span></h5>
                    <p>${element.reviewResponseBody}</p>
            </div>`
          : '' }
       </div>
    </div>
 </div>`
}

function reviewPagination(sectionCount:any){
    let pageCount =  sectionCount / pageReviewSize;
    let separatorAdded:Boolean = false;
    render();
    function isPageInRange( pageReviewNumber:any, index:any, maxPages:any, pageBefore:any, pageAfter:any){
        if (index <= 1){
            return true;
        }
        if (index >= maxPages - 2){
            return true;
        }
        if (index >= pageReviewNumber - pageBefore && index <= pageReviewNumber + pageAfter) {
            return true;
        }
    }
    function render(){
        $(".reviews-pagination-section ul.pagination").empty();
        $(".reviews-pagination-section ul.pagination").prepend('<li><a class="pagination-item first" href="#">Back</a></li>');
        for(let i = 0 ; i<pageCount;i++){
                if ( isPageInRange( pageReviewNumber, i, pageCount, 2, 2 ) ) {
                    $(".reviews-pagination-section ul.pagination").append('<li><a href="#" class="pagination-item pagingNumber"  data-page='+(i+1)+'>'+(i+1)+'</a></li> ');
                    separatorAdded = false;
                }else{
                    if (!separatorAdded) {
                        $(".reviews-pagination-section ul.pagination").append('<li class="separator" />');
                        separatorAdded = true;
                    }    
                }
        }
        $(".reviews-pagination-section ul.pagination").append('<li><a class="pagination-item last" href="#">Next</a></li>');
        if(pageReviewNumber == 1){
            $('.first').addClass('disabled');
        }
        if(pageReviewNumber == Math.ceil(pageCount)){
            $('.last').addClass('disabled');
        }
        $('a[data-page='+pageReviewNumber+']').addClass("current");
        $(".reviews-pagination-section li a.pagingNumber").click(function(this:any, e:any) {
            e.preventDefault();
            pageReviewNumber = parseInt($(this).attr('data-page'));
            updateReviewUrl();
            render();
        });

        $('.reviews-pagination-section .last').on('click',function(e:any){
            e.preventDefault();
            pageReviewNumber++;
            updateReviewUrl();
            render();
            
        });
        $('.reviews-pagination-section .first').on('click',function(e:any){
            e.preventDefault();
            pageReviewNumber--;
            updateReviewUrl();
            render();
        });
    }
}