import { $ } from "../third-party-imports";
import { apiConfig } from "../config/apiConfig";
import { stopLoader } from "./stopLoader";
import axios from "axios";
export function getStateFun()
{

    if ($('select[name="estimateInputState"]').length > 0)
    {
        let referenceDetailsVal = $('select[name="estimateInputState"]').val();
        if (referenceDetailsVal == '' || referenceDetailsVal == '0')
        {
            initStateCall();
        }
    }
    if ($('.setState').length > 0)
    {
        let setState = $('.setState').val();
        if (setState == '' || setState == '0')
        {
            initStateCall();
        }
    }
}

function initStateCall()
{
    var baseUrl = apiConfig.stateApi;
    let conceptCode = $('#conceptCode').val();
    let country = $('#countryCode').val();
    var countryCode = country.toUpperCase();
    if (conceptCode == 'FSP')
    {
        let caTest = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(localStorage.getItem('zipcode'));
        if (caTest)
        {
            profileStateApiCa(baseUrl, countryCode);
        } else
        {
            profileStateApiUs(baseUrl, countryCode)
        }
    }  else if(conceptCode == 'HMC'){ 
        profileStateApiUs(baseUrl, countryCode)
    } else
    {
        if (country == 'ca')
        {
            profileStateApiCa(baseUrl, "US");
        } else
        {
            profileStateApiUs(baseUrl, countryCode)
        }

    }
}

function profileStateApiCa(baseUrl: any, countryCode: any)
{
    var states: any = [];
    let apiUrl = baseUrl + "CA?apikey=" + window.DEFINE_VALUE.JS_API_KEY;
    let config = {
        method: 'get',
        url: apiUrl,
        headers: {
            'Content-Type': 'application/json'
        }
    };
    axios(config)
    .then(function (response:any) {
            $(".setState").siblings("label[for='inputstate']").text('State*');
            $.each(response.data, function (index: any, element: any)
            {
                states.push('<option id="' + element.stateCode + '" value="' + element.stateCode + '">' + element.stateName + '</option>');
            });
            $(".setState").siblings("label[for='inputstate']").text('Provinces*');
    let configObj = {
        method: 'get',
        url: baseUrl + countryCode + "?apikey=" + window.DEFINE_VALUE.JS_API_KEY,
        headers: {
            'Content-Type': 'application/json'
        }
    };
        axios(configObj)
        .then(function (responseObj:any) {
            $.each(responseObj.data, function (index: any, element: any)
            {
                states.push('<option id="' + element.stateCode + '" value="' + element.stateCode + '">' + element.stateName + '</option>');
            });
            $('.setState').empty();
            $('.setState').append('<option value="">Select State/Province</option>');
            $('.setState').append(states);
            $('select[name="estimateInputState"]').empty();
            if($("#brandName").val() === 'mr-rooter'){
                $('select[name="estimateInputState"]').append('<option value="">Select Province</option>');
            }else {
                $('select[name="estimateInputState"]').append('<option value="">Select a state</option>');
            }
            $('select[name="estimateInputState"]').append(states);
            $('select[name="blogInputState"]').empty();
            $('select[name="blogInputState"]').append('<option value="">Select</option>');
            $('select[name="blogInputState"]').append(states);
            $('select[name="mrhState"]').empty();
            $('select[name="mrhState"]').append('<option value="">Select an option</option>');
            $('select[name="mrhState"]').append(states);
            $('select[name="inputstate"]').empty();
            $('select[name="inputstate"]').append('<option value="">Select an option</option>');
            $('select[name="inputstate"]').append(states);
            $('.select-option').empty();
            $('.select-option').append('<option value="">Select an option</option>');
            $('.select-option').append(states);
           })
           .catch(function (error:any) {
            stopLoader();
           })
        })
       .catch(function (error:any) {
        stopLoader();
    });
}
function profileStateApiUs(baseUrl: any, countryCode: any)
{
    var states: any = [];
    let apiUrl = baseUrl + countryCode + "?apikey=" + window.DEFINE_VALUE.JS_API_KEY;
    let config = {
        method: 'get',
        url: apiUrl,
        headers: {
            'Content-Type': 'application/json'
        }
    };
    axios(config)
    .then(function (response:any) {
        $.each(response.data, function (index: any, element: any)
            {
                states.push('<option id="' + element.stateCode + '" value="' + element.stateCode + '">' + element.stateName + '</option>');
            });
            $('.setState').empty();
            if($('#conceptCode').val() =="PLG"){
                $('.setState').append('<option value="">Select State</option>');
            }
            else{
                $('.setState').append('<option value="">Select State/Province</option>');
            }
            $('.setState').append(states);
            $('select[name="estimateInputState"]').empty();
            $('select[name="estimateInputState"]').append('<option value="">Select a state</option>');
            $('select[name="estimateInputState"]').append(states);
            $('select[name="blogInputState"]').empty();
            $('select[name="blogInputState"]').append('<option value="">Select</option>');
            $('select[name="blogInputState"]').append(states);
            $('select[name="mrhState"]').empty();
            $('select[name="mrhState"]').append('<option value="">Select an option</option>');
            $('select[name="mrhState"]').append(states);
            $('select[name="inputstate"]').empty();
            $('select[name="inputstate"]').append('<option value="">Select an option</option>');
            $('select[name="inputstate"]').append(states);
            $('.select-option').empty();
            $('.select-option').append('<option value="">Select an option</option>');
            $('.select-option').append(states);
    })
}