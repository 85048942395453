import { $ } from "../third-party-imports";
import { searchServiceListGenerator } from "./search-service-page";
import { serviceMenuWebCatgoryProtypePage } from "./search-web-category-protype";

export function searchServiceInitial()
{
    let brandName = $('#brandName').val();
    let searchTermVal = sessionStorage.getItem('searchInput')?.toLowerCase();
    if (brandName === "mr-handyman")
    {
        let pathName = window.location.pathname;
        let searchPage = pathName.includes("/services/search/");
        let root = JSON.parse(sessionStorage.getItem('filterService'));
        if (root)
        {
            serviceMenuWebCatgoryProtypePage(root);
        }
        if (searchPage)
        {
            let filterArray: any = [];
            searchTermVal = searchTermVal?.trim();
            let multipleTerms: any = [];
            if (searchTermVal)
            {
                multipleTerms.push(searchTermVal);
                let nameArr = searchTermVal.split(' ');
                nameArr.forEach(nm =>
                {
                    multipleTerms.push(nm);
                });
            }
            multipleTerms.forEach((term: any) =>
            {
                root.map((m: any) =>
                {
                    m.category.map((cat: any) =>
                    {
                        if (cat.serviceType.toLowerCase() == 'otherservice')
                        {
                            otherServiceAvailable(cat.category, filterArray, term, false);
                        }
                        filterCatgroyMenuService(cat, filterArray, term, false);
                    });
                });

            });

            multipleTerms.forEach((term: any) =>
            {
                root.map((m: any) =>
                {
                    m.category.map((cat: any) =>
                    {
                        let link = term?.replace(/\s/g, '-');
                        if (cat.serviceType.toLowerCase() == 'otherservice')
                        {
                            otherServiceAvailable(cat.category, filterArray, link, true);
                        }
                        filterCatgroyMenuService(cat, filterArray, link, true);
                    });
                });

            });

            const uniqueArray = filterArray.filter((value: any, index: any, self: any) =>
                index === self.findIndex((t: any) => (
                    t.link === value.link
                ))
            )
            searchServiceListGenerator(uniqueArray, searchTermVal);
        }
    }
    function otherServiceAvailable(category: any, filterArray: any, term: any,linkUrl:any)
    {
        category.map((cat: any) =>
        {
            filterCatgroyMenuService(cat, filterArray, term,linkUrl);
        });
    }

    function filterCatgroyMenuService(cat: any, filterArray: any, namecheck: any, linkUrl:any)
    {
        if (linkUrl)
        {
            if(cat.link != null) {
            if (cat.link?.includes(namecheck))
            {
                filterArray.push(cat);
                cat?.menu?.map((mn: any) =>
                {
                    if (mn.link != null) {
                        if (mn.link.toLowerCase().includes(namecheck)) {
                            filterArray.push(mn);
                            checkingServiceLinkName(
                                mn,
                                filterArray,
                                namecheck,
                                linkUrl
                            );
                        }
                    }
                });
            } else
            {
                cat?.menu?.map((mn: any) =>
                {  
                    if(mn.link != null){
                        if (mn.link.toLowerCase().includes(namecheck))
                        {
                            filterArray.push(mn);
                            checkingServiceLinkName(mn, filterArray, namecheck,linkUrl);
                        } else
                        {
                            checkingServiceLinkName(mn, filterArray, namecheck,linkUrl);
                        }
                    }
                });
            }
        }
        } else
        {
            if (cat.name?.includes(namecheck))
            {
                filterArray.push(cat);
                cat?.menu?.map((mn: any) =>
                {
                    if (mn.name?.toLowerCase().includes(namecheck))
                    {
                        filterArray.push(mn);
                        checkingServiceLinkName(mn, filterArray, namecheck,linkUrl);
                    }
                });
            } else
            {
                cat?.menu?.map((mn: any) =>
                {
                    if (mn.name?.toLowerCase().includes(namecheck))
                    {
                        filterArray.push(mn);
                        checkingServiceLinkName(mn, filterArray, namecheck,linkUrl);
                    } else
                    {
                        checkingServiceLinkName(mn, filterArray, namecheck,linkUrl);
                    }
                });
            }
        }
 
    }

    function checkingServiceLinkName(mn: any, filterArray: any, namecheck: any,linkUrl:any)
    {
        if(linkUrl){
            mn.service.map((ser: any) =>
            {
                if (ser.link?.toLowerCase().includes(namecheck))
                {
                    filterArray.push(ser);
                }
            });
        }else{
            mn.service.map((ser: any) =>
            {
                if (ser.name?.toLowerCase().includes(namecheck))
                {
                    filterArray.push(ser);
                }
            });
        }
       
    }
}