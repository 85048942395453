import { $ } from "../third-party-imports";
import { stopLoader } from "./stopLoader";
import { apiConfig } from "../config/apiConfig";
import axios from "axios";
import { updateCroNumberAndDba } from "../scheduler/cro-os-flow/cro-os-flow";
export function getFullAttributeApi(franchiseWebLocationId:any,successHandler:any){
    // let apiUrl = apiConfig.fullAttribute+franchiseWebLocationId+'&apikey=' + window.DEFINE_VALUE.JS_API_KEY;
    let apiUrl = apiConfig.fullAttribute + franchiseWebLocationId +'/0';

    var transactionID = sessionStorage.getItem('corelationID');
    var sessionID = sessionStorage.getItem('sessionID');
    let correlationId = `correlationId=${transactionID}&sessionId=${sessionID}`;

    let config = {
        method: 'get',
        url: apiUrl,
        headers: {
            'Content-Type': 'application/json',
            'apikey': window.DEFINE_VALUE.JS_API_KEY,
            'X-Corellation-Id': correlationId
        }
    };
    axios(config)
    .then(function (response:any) {
            let data = response.data;
            data = data.fullAttributeSearch;
            var phoneNumber:any;
            var locationDoingBusinessAs = data.locationDoingBusinessAs;
            var brandCode = data.brandCode;
            if(window.objOSGlobal.requestBodyValues.fsaLead){
                var calltrackObj = data.callTrackings.filter((obj:any) => obj.franchiseWebLocationCallTrackingTypeID == 2);
                phoneNumber = calltrackObj[0]?.callTrackingNumber ? calltrackObj[0]?.callTrackingNumber.toString().match(/(\d{3})(\d{3})(\d{1}.*)/) : null;
                if(phoneNumber == null){
                    phoneNumber = data.mainPhoneNumber ? data.mainPhoneNumber.toString().match(/(\d{3})(\d{3})(\d{1}.*)/) : null;
                 }
                if (phoneNumber){
                    phoneNumber = "(" + phoneNumber[1] + ") " + phoneNumber[2] + "-" + phoneNumber[3];
                }
            }else{
                 phoneNumber = window.objOSGlobal.paramsUI.brandPhoneNumber;
            }
            $('.call-details-text .phnNumber').empty();
            $('.call-details-text').removeClass('d-none');
            if(!$('.modal-header-two .phnNumber').hasClass('phnNumber')){
                $('.modal-header-two .call-details-text a').append("<span class='phnNumber'>"+phoneNumber+"</span>");
            }
          
            $('.call-details-text a .phnNumber').text(phoneNumber);
            $('.call-details-text a').attr('href',"tel:"+phoneNumber); 
            $('.modal-header-two .call-details-text a').attr('href',"tel:"+phoneNumber);

            $('.call-details .col-md-9 .contact-right .body-text-sm a.call-txt').attr('href',"tel:"+phoneNumber);
            $('.call-details .col-md-9 .contact-right .body-text-sm a.call-txt').append("<b class='phnNumber'>"+phoneNumber+"</b>");
          
            $('.mm_modal-call a.call-icon').attr('href',"tel:"+phoneNumber);
            let childEle = $('.mm_modal-call a.call-icon').children();
            $('.mm_modal-call a.call-icon').text(' '+phoneNumber).prepend(childEle);
            $('.schedulerModalLabel').text(locationDoingBusinessAs);
            $('.schedulerModalLabel').removeClass('d-none');
            $('.phone-number').text(phoneNumber);
            window.objOSGlobal.paramsUI.brandPhoneNumber = phoneNumber;
            window.objOSGlobal.paramsUI.doingBusinessAs = locationDoingBusinessAs;
            window.objOSGlobal.paramsUI.localfranchiseWebLocationId = franchiseWebLocationId;
            window.objOSGlobal.requestBodyValues.licenseNumber = data.contractNumber;
            window.objOSGlobal.requestBodyValues.IsLeadOnly = data.onlineScheduling ? false : true;
            window.objOSGlobal.requestBodyValues.calendarLead = data.onlineScheduling ? false : true;
            window.objOSGlobal.requestBodyValues.optInForPOSScheduling = data.options?.optInForPOSScheduling;
            window.objOSGlobal.requestBodyValues.optInForPOSScheduling2 = data.options?.optInForPOSScheduling2;
            window.objOSGlobal.requestBodyValues.locationWebsiteUrl = data?.locationWebsiteUrl;
            window.objOSGlobal.requestBodyValues.localStateCode = data?.state;
                                    let items=window.objOSGlobal.requestBodyValues.brandJsonGlobalObj;
updateCroNumberAndDba();
						if(items?.length) {
                            var alternateConcept = data.alternateConcept;
			                if(alternateConcept != null && typeof(alternateConcept) != 'undefined') {				
							    var alternateBrandData = $.grep(items[0].AlternateBrandList, function (b:any) { return b.alternate_brand === alternateConcept; });
							    if(alternateBrandData.length) {
								    var logo = alternateBrandData[0].image;
								    							    }
                            }
                        else{
                            var logo = items[0].image;
                        }
                       if($("#conceptCode").val() === 'FSP' || $("#conceptCode").val() === 'MHM' || $("#conceptCode").val() === 'MRH') {
                            let logo_src;
                            let brandName = $("#conceptCode").val();
                            let brandDbaName;
                            if($("#full_dbaName").val()){
                                if(brandName === 'FSP'){
                                     brandDbaName ='protectpainters';
                                }
                            else if( brandName==='MHM'|| brandName==='MRH'){
                                brandDbaName = 'myhandyman'
                                }
                            }
                            const checkaltBrand = window.objOSGlobal.paramsUI.doingBusinessAs.toLowerCase().replaceAll(" ", '').includes(brandDbaName);
                            checkaltBrand ? (logo_src = items[0].AlternateBrandList[0].image) : (logo_src = items[0].image);
                            $('.result-stack-logo img, .header-brand-logo').attr('src', logo_src);
                            }else {
                            $('.result-stack-logo img, .header-brand-logo').attr('src', logo);
                            }
                        }else {
                        $('.result-stack-logo img, .header-brand-logo').attr('src', logo);
                        }
                        
        if(successHandler){
            successHandler();
        }
    }).catch(function (error:any) {
        stopLoader();
       })
}