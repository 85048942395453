export function commonAjaxCallfunctionHidingMenu(galleryapi: any, headers: any, successHandler: any)
{
    $.ajax({
        type: "GET",
        url: galleryapi,
        headers: headers,
        contentType: "application/json",
        dataType: 'json',
        success: successHandler,
        error: function (error)
        {
        }
    });
}