import { $ } from "../third-party-imports";
export function setAlternateBrandData() {
	var alternateConcept = localStorage.getItem('alternateConcept');
	var franchiseWebLocationId = localStorage.getItem('franchiseWebLocationId');
	var brands = window.objOSGlobal.requestBodyValues.Country == 'CA' ? "/ca/en-ca/_assets/brands.json" : "/us/en-us/_assets/brands.json";;
	
	
	if(alternateConcept != null && typeof(alternateConcept) != 'undefined' && franchiseWebLocationId != null && typeof(franchiseWebLocationId) != 'undefined'	&& franchiseWebLocationId == $('#weblocationId').val()) {
		$.getJSON(brands, function (result) { 
			if (result.length > 0) {
				var items = $.grep(result, function (e:any) { return e.code === $('#conceptCode').val() }); //window.objOSGlobal.requestBodyValues.ConceptId;
				window.objOSGlobal.requestBodyValues.brandJsonGlobalObj = items;
				if(items.length) {
					var alternateBrandData = $.grep(items[0].AlternateBrandList, function (b:any) { return b.alternate_brand === alternateConcept; });
					if(alternateBrandData.length) {
						if($('.navbar-brand img').length) {
							$('.navbar-brand img').attr('src', alternateBrandData[0].image);
						}
						else {
							$('.navbar-brand').append('<img src="' + alternateBrandData[0].image + '" />');
						}
						$('.call-icon').get(0).lastChild.nodeValue = alternateBrandData[0].phone;
						$('strong.call-icon').get(0).lastChild.nodeValue = alternateBrandData[0].phone;
						$('.call-icon').attr('href', 'tel:' + alternateBrandData[0].phone);						
					}
				}
			}
		});
	}
}