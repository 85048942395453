
import { mappings } from '../constants/utmCampaignNameMappings'

//US-305774 & 328993 -UTM attributes to be passed in the booking API start
export function setUtmAttributes(){
    const params = new URL(window.location.href);
    
    const utm_campaign = params.searchParams.get("utm_campaign");
    const utm_source = params.searchParams.get("utm_source"); 
    let decryptedCampaignName='';
    if(utm_campaign || utm_source){
        let marketingCampaign = "N/A"
        let marketingSource = "N/A"
        if (utm_campaign)
        {
            marketingCampaign = utm_campaign;
            sessionStorage.setItem('campaign_name', utm_campaign);
            decryptedCampaignName =  decryptCampaignName(utm_campaign);
        }
        if (utm_source)
        {
            marketingSource = utm_source;
        }
        const leadAdditionalNotes = {
            LeadAdditionalNoteLabel: `MarketingSource:${marketingSource}, MarketingCampaign:${decryptedCampaignName || marketingCampaign}`,
            LeadAdditionalNoteText: ""
        };
        try {
            sessionStorage.setItem('lead_additional_notes', JSON.stringify(leadAdditionalNotes));
        } catch (error) {
            console.error('Failed to save lead additional notes in Session Storage:', error);
        }
    }
}
//US-305774 & 328993 -UTM attributes to be passed in the booking API end

//US-337177 Marketing Lead Source Comment Notes Display Friendly Name to Owners start
function decryptCampaignName(utm_campaign:string){
    const field_arr=utm_campaign.split('_');
    const categories:string[] = [
        'Brand',
        'Country',
        'FundingSource',
        'PaidOrNonPaid',
        'CampaignType',
        'Funnel'
    ];
    // let decryptedCampaignName = '';
    const mappedParts = field_arr.map((part:string, index) => {
        const category:string = categories[index];
        return mappings[category]?.[part] || part;
    })
    return mappedParts.join('_');;
}
//US-337177 Marketing Lead Source Comment Notes Display Friendly Name to Owners end