import { $ } from "../third-party-imports";
import { startLoader } from "../util/startLoader";
import { redirectZipCode } from '../util/redirectZipCode';
import { getDisplayServicesList } from '../services/getDisplayServicesList';
import { servicesListDisplayModal } from "../util/servicesListDisplayModal";
import { refreshScheduleData } from '../util/refreshScheduleData';
// import { addressDetailsScreen } from './addressDetailsScreen';
import { animation, slotIn } from '../util/refreshScheduleData';
import { storeProTypeID } from '../services/storeProTypeID';
import { GetReferenceDetails } from '../util/GetReferenceDetails';
import { getDisplayServicesResults } from '../services/getDisplayServicesResults';
import { stopLoader } from "../util/stopLoader";
import { getServiceType } from '../services/getServiceType';
import { serviceSearchTermApi } from '../services/serviceSearchTermApi';
import { localBrandsSitesFlow } from '../util/localBrandsSitesFlow';
import { contactInformationScreen } from './contactInformationScreen';
import { dateDisplay } from '../util/dateDisplay';
import { signIn } from '../auth/authRedirect/signIn';
import { leadConfirmationCall } from './leadConfirmationCall';
import { FsaOSFlowScenarion, initialCallFunction } from './initialCallFunction';
import { commercialFlow } from './commercialFlow';
import { getStateFun } from '../util/getStateFun';
import { mollyMaidServicesList } from './mollyMaidServicesList';
import { categoryPage, FspSchedulerFlow, getFullAttributeApi, getGTMPageData } from "../util";
import { servicePage } from "../services";
import { getBrandJsonValue, getWeekObj, servieModal } from "../util/get-brands-json";
import { estFormBookingApiCall, EstimateForm } from '../util/estimateForm';
import { estimateFromConfirmationCall, thankYouPageConfirmation } from '../util/estimateFromConfirmationCall';
import { confimationScreen } from "./confirmation-secreen";
import { removeClassForUpdateFlow } from "../util/updated-opus-online-scheduler-flow";
import { setLeadOnly } from "./setLeadOnly";
import { confirmationClickEvent } from "../util/confirmation-click-event";
import { leadConfirmationCallApiPlg } from "./plg-scheduler-flow";
//import { LeadConfirmation } from "../services/LeadConfirmation";
import { mrhServiceModal } from "../util/mrh-service-modal";
import { serviceEntryBtn } from "./serviceEntryBtn";
import { mrrConfirmationTextColor } from "./mrr-os-flow/mrr-os-flow";
import _ from "lodash";
import { irvinrewardFormConfirmationPage } from "../util/irvingRewards";
import { shortFormConfirmationText } from "../util/cro-short-form";

export const schedulerState = {
    profileObj: {} as any,
    dateStart: '' as any,
    page: 0,
    numberOfSlot: 1,
    incr: 1,
    decr: 0,
    selectedDate: undefined as any,
    postalCode: "",
}
window.objOSGlobal = {
    "paramsUI": { "doingBusinessAs": "", "franchiseLogoPath": "", "brandPhoneNumber": "", "serviceneeds": "", "wheredidyouhear": "", "onetime": "", "moveinout": "", },
    "requestBodyValues": { "FranchiseId": "", "WebLocationId": "", "C4ChannelTypeId": 0, "Comments": "", "ConceptCalledId": "", "ConceptName": "", "ConceptCode": "", "C4LeadsEnabled": true, "VendorId": "", "VendorName": "", "IsTest": true, "C4CallCenterId": 0, "Note": "", "FirstName": "", "LastName": "", "Email": "", "Phone": "", "PhoneType": "", "PostalCode": "", "City": "", "State": "", "Country": "US", "Address": "", "Address2": "", "leadOrigin": "Web", "leadSource": "Web", "CampaignName": "N/A", "CallType": "In Bound", "EmailOptOut": false, "Warranty": false, "MakeId": 0, "licenseNumber": "", "LeadSourceId": 0, "NeighborlyLeadSourceId": 0, "NeighborlyAccountId": 0, "ServiceSkuId": 0, "IsLeadOnly": true, "ScheduleID": "", "LeadAdditionalNoteLabel": "Website", "IsBetaTester": false, "SignUpForUpdates": false, "serviceTypeID": 0, "fsaLead": true, "irvingrewardsform":false,"plgcontactform":false,"isFindYourLocation":false}
}

window.initGMap = function () {
    $('#main').append(`<div id="initMap" class="initMap d-none hidden" style="display:none"></div>`);
    var map = new google.maps.Map(document.getElementById('initMap'), {
        center: {lat: -34.397, lng: 150.644},
        zoom: 8
    });
} 

$(window).on("load", function () {
    setTimeout(function () {
        initialCallFunction();
    }, 1000);
    $(document).on('change', '.search-services-form .form-check input[name="chkServiceType"]', function (this: any) {
        $('#service-search-row').remove();
        var serviceType = $(this).val();
        var postalCode = window.objOSGlobal.requestBodyValues.PostalCode
        window.objOSGlobal.requestBodyValues.serviceType = serviceType;
        window.objOSGlobal.requestBodyValues.modifier = $(this).siblings('.radio-text').text();
        sessionStorage.setItem('ServiceModifierValue', window.objOSGlobal.requestBodyValues.modifier);
        startLoader();
        getDisplayServicesList(postalCode, serviceType, function (result: any, status: any, xhr: any) {
            getDisplayServicesResults(result, postalCode);
            stopLoader();
        }, function (data: any, status: any, error: any) {
            stopLoader();
            $(".tab-pane.fieldset").removeClass("active");
            $(".no-service-screen").addClass("active");
        });
    });
    if (window.location.href.indexOf("confirmation") > -1) {
        let conceptID = $('#conceptCode').val()?.toLowerCase();
        if (conceptID === 'plg') {
            let glassTypeAuto = sessionStorage.getItem('glass-type-auto');
            if (glassTypeAuto == 'No') {
                $(".appointment-contact .col-sm-4:first-child").removeClass("d-none");
            } else {
                $(".appointment-contact .col-sm-4").removeClass("d-none");
            }
        }
    }
})
let country:any = $("#countryCode").val();
getGTMPageData(country);

// export const id = setInterval(waitForProfile, 500);

$('#schedulerWizard button.close').click(function () {

    $('.schedulerModalLabel').addClass('d-none');
    $('p.call-details-text').addClass('d-none');
    sessionStorage.removeItem('servicePageClick');
    $('.schedulerModalLabel').empty();
    localStorage.removeItem('home-scheduler-btn');
    $('.scheduler-modal .modal-content').addClass('banner-wizard');
    if ($("#brandName").val() === 'mr-handyman' || $("#brandName").val() === "my-handyman") {
        $('.progress, .progressbar').hide();
    }
});

$('.services-btn').click(function (this: any, e: any) {
    e.preventDefault();
    var serCatId = $(this).attr('id');
    if (serCatId != "a_servicepage_bannerwithcta_button" && serCatId != 'a_servicepage_fullwidthcta_button') {
        var postal: any = $('.cta-form #inputHeroZipCode').val() ? $('.cta-form #inputHeroZipCode').val() : localStorage.getItem('zipcode')
        if (postal) {
            $('.tab-pane.fieldset').removeClass('active');
            if (!redirectZipCode(postal)) {
                return;
            }
            window.objOSGlobal.requestBodyValues.serviceType = '4';
            window.objOSGlobal.requestBodyValues.PostalCode = postal;
            localStorage.setItem('zipcode', postal);
            servicesListDisplayModal(postal)
        } else {
            $('#js-schedulerDialog').modal('show');
        }
    }
});

$('.cta-form.cta-wrapper a.services-btn').click(function (e: any) {
    e.preventDefault();
    getStateFun();
    var webLocationId = $("#weblocationId").val();
    webLocationId = webLocationId ? webLocationId : localStorage.getItem('localWebLocationId');
    var postalCode = localStorage.getItem('zipcode');
    $(".tab-pane.fieldset").removeClass("active");
    if (webLocationId && postalCode) {
        getBrandJsonValue();
        window.objOSGlobal.requestBodyValues.PostalCode = postalCode;
        window.objOSGlobal.requestBodyValues.WebLocationId = webLocationId;
        if (servieModal.enable) {
            $('#js-schedulerDialog').modal('show');
            let conceptCode = $('#conceptCode').val();  
            let isWebSite = $("#countryCode").val()?.toLowerCase();
            let brandJsonItems = window.objOSGlobal.requestBodyValues.brandJsonGlobalObj;
            const croleadNoZipEnable = brandJsonItems[0].enable_cro_enhance_lead_no_zip_match || false ;
            if(conceptCode?.toLowerCase() ==="mrr" && isWebSite ==="ca" || croleadNoZipEnable){
                if($(".search-services-form").valid()){
                    FsaOSFlowScenarion(postalCode?.trim());
                }
            }else{
                FspSchedulerFlow(postalCode);
            }
           
        } else {
            $('#js-schedulerDialog').modal('show');
            var pageType = $('#pageType').val();
            if (pageType == 'servicePage') {
                getFullAttributeApi(window.objOSGlobal.requestBodyValues.WebLocationId, () => {
                    servicePage();
                });
            }
            if (pageType == 'categoryPage') {
                categoryPage();
            }
        }

    } else {
        sessionStorage.setItem('servicePageClick', 'true');
        $('#js-schedulerDialog').modal('show');
        $('.zip-entry-screen').addClass('active');
        $('.scheduler-modal .modal-content').addClass('banner-wizard');
    }
});

$(".address-details-screen").on("click", ".click-schedule", function () {
    $('.address-details-screen h2').text('Service Address');
    $('.address-details-screen h5').remove();
    $('.address-details-screen .js-next-btn').show();
    getFullAttributeApi(window.objOSGlobal.requestBodyValues.WebLocationId, function () {
        if (!window.objOSGlobal.requestBodyValues.IsLeadOnly) {
            schedulerState.numberOfSlot = 1;
            $('.calendar-screen').addClass('active');
            let nextId = $("#service-search-row .pointer").parents('.service-search-screen').next().attr("id");
            refreshScheduleData(new Date().getFullYear(), new Date().getMonth() + 1);
            $('.nav-wizard.progressbar').removeClass('hide-schedule');
            if ($('#conceptCode').val() == 'MRA') {
                $('[href="#step3"]').tab('show');
            } else {
                $('[href="#' + nextId + '"]').tab('show');
            }
            $('.calendar-wrapper .selected-date').addClass('d-none');
        } else {
            $('.nav-wizard.progressbar').addClass('hide-schedule');
            $('.contact-information-screen').addClass('active');
        }
        $('.progress-bar').css({ width: '3.2%' });
    })
    $('.address-details-screen').removeClass('active');
})

$('.header-classic .desktop-btn.text-right a').off('click').on('click', function (e: any) {
    e.preventDefault();
    localStorage.removeItem('serviceCategoryPage');
    var elem = document.getElementById("aws-sdk")
    if (!elem) {
        var script = document.createElement('script');
        script.setAttribute('type', 'text/javascript');
        script.async = true;
        script.defer = true;
        script.id = "aws-sdk";
        script.setAttribute('src', 'https://sdk.amazonaws.com/js/aws-sdk-2.927.0.min.js');
        document.body.appendChild(script);
    }
});
$('.warranty-screen .next').off().on('click',async function () {
    if (!$("#warrantyDetails").valid()) {
        return;
    }
    var val: any = [];
    $('.prolemsCheckBox:checkbox:checked').each(function (this: any, i: any) {
        val[i] = $(this).val();
    });
    var makeId = $('.warranty-screen select[name="brandAppliance"]').children(":selected").attr("id");
    window.objOSGlobal.requestBodyValues.ServiceTypeProblem = val.join();
    window.objOSGlobal.requestBodyValues.MakeId = Number(makeId);
    if (!window.objOSGlobal.requestBodyValues.IsLeadOnly) {
        if (!$("#warranty").is(":checked")) {
            startLoader();
            schedulerState.numberOfSlot = 1;
            animation.loaderFlag = true;
            await refreshScheduleData(new Date().getFullYear(), new Date().getMonth() + 1);
            stopLoader();
        }
    }
});
$('.address-details-screen input[name="inputZipCode"]').keyup(function (this: any) {
    var value: any = $(this).val();
    var regex = new RegExp(/^([0-9]{5}|[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9])$/);
    let isleadOnlyCheck = window.objOSGlobal.requestBodyValues.IsLeadOnly;
    if (regex.test(value)) {
        window.objOSGlobal.requestBodyValues.PostalCode = value;
        if (localStorage.getItem('zipcode') != value) {
            sessionStorage.setItem('changeZip', value);
            if (isleadOnlyCheck && window.objOSGlobal.requestBodyValues.ConceptCode == 'MLY') {
                $('.mly-service-contact-form-screen').removeClass('confirmation-screen');
            }
        } else {
            if (isleadOnlyCheck && window.objOSGlobal.requestBodyValues.ConceptCode == 'MLY') {
                $('.mly-service-contact-form-screen').addClass('confirmation-screen');
            }
            sessionStorage.removeItem('changeZip');
        }
    }
});

$(document).on("click", "a.pointer", function (this: any, e: any) {
    e.preventDefault();
    schedulerState.numberOfSlot = 1;
    var serviceName = $(this).text();
    $('#warrantyDetails input[name="serviceListQuery"]').val(serviceName);
    var serviceSkuId = $(this).attr("id");
    var serviceId = $(this).parent().attr("id");
    window.objOSGlobal.requestBodyValues.serviceSkuId = serviceSkuId;
    window.objOSGlobal.requestBodyValues.ServiceId = serviceId;
    window.objOSGlobal.requestBodyValues.ServiceSkuIds = [serviceSkuId];
    window.objOSGlobal.requestBodyValues.serviceName = serviceName;
    localStorage.setItem("estserviceSkuId", window.objOSGlobal.requestBodyValues.serviceSkuId);
    localStorage.setItem("estServiceSkuIds", JSON.stringify(window.objOSGlobal.requestBodyValues.ServiceSkuIds))
    localStorage.setItem("estServiceId", window.objOSGlobal.requestBodyValues.ServiceId);
    if ($("#brandName").val() != "molly-maid") {
        console.log('serviceSkuId 1', serviceSkuId);
        storeProTypeID(serviceSkuId);
    } else {
        commercialFlow();
    }
});
$('.how-often-screen .next').click(function (this: any, e: any) {
    window.objOSGlobal.requestBodyValues.IsLeadOnly = window.objOSGlobal.requestBodyValues.calendarLead
    e.preventDefault();
    $('.scheduler-modal .modal-content').removeClass('banner-wizard');
    let selectAbtHome = localStorage.getItem('selectedAboutHome');
    let mlyCleaning = sessionStorage.getItem('MlyCleaning');
    let nextId = $(this).parents('.how-often-screen').next().attr("id");
    var isEstimateClass = $('.progressbar').hasClass('hide-estimate');
    var isServiceListClass = $('.progressbar').hasClass('hide-servicesList');
    animation.loaderFlag = true;
    if (selectAbtHome == "onetime") {
        if (mlyCleaning == "No") {
            window.objOSGlobal.requestBodyValues.Note = 'MLY Frequency: One Time, Not a Move in or Move out clean'
        } else {
            window.objOSGlobal.requestBodyValues.Note = 'MLY Frequency: One Time, Move in or Move out clean'
        }
    }
    if (selectAbtHome == "recurring") {
        var mlyFrequency = $('input[name="chkFrequencyMethod"]:checked').siblings('.radio-txt').text();
        window.objOSGlobal.requestBodyValues.Note = 'MLY Frequency: Recurring, ' + mlyFrequency;
    }
    if (window.objOSGlobal.requestBodyValues.IsLeadOnly) {
        $('.progressbar').addClass('isLeadOnlyCls');
        $('.progressbar li > a[href="#step4"]').parent().addClass('isLeadABoutCls');
        GetReferenceDetails();
        if (!isEstimateClass || !isServiceListClass) {
            $('.progressbar li').css('width', 'calc(100%/5)');
        } else {
            $('.progressbar li').css('width', 'calc(100%/4)');
        }
        $('.schedule-list').hide();
        $('.tab-pane.fieldset').removeClass('active');
        $('.about-home-screen').addClass('active');
        $('.progress-bar').css({ width: '3.2%' });
    } else {
        $('.progressbar').removeClass('isLeadOnlyCls');
        $('.progressbar li > a[href="#step4"]').parent().removeClass('isLeadABoutCls');
        schedulerState.numberOfSlot = 1;
        refreshScheduleData(new Date().getFullYear(), new Date().getMonth() + 1);
        $('.schedule-list').show();
        $('[href="#' + nextId + '"]').tab('show');
    }
})
$(document).on("click", ".categoryListLink", function (this: any, e: any) {
    e.preventDefault();
    $('.tab-pane.fieldset').removeClass('active');
    schedulerState.numberOfSlot = 1;
    var serviceName = $(this).text();
    var serviceSkuId = $(this).parent().attr("id");
    window.objOSGlobal.requestBodyValues.serviceSkuId = serviceSkuId;
    window.objOSGlobal.requestBodyValues.serviceName = serviceName;
    if ($("#brandName").val() != "molly-maid") {
        storeProTypeID(serviceSkuId);
    } else {
        commercialFlow();
    }
})

if ($(".search-services-form input[name='serviceListQuery']").length) {

    $(".search-services-form input[name='serviceListQuery']").keyup(function (this: any) {
        $(".servicesListDropdown").empty();
        // $(".service-search-submit").addClass("disble-btn").prop("disabled", true);
        var search_term: any = $(this).val();
        var serviceTypeName = window.objOSGlobal.requestBodyValues.serviceType;
        var serviceType = getServiceType(serviceTypeName);
        var webLocationId = window.objOSGlobal.requestBodyValues.WebLocationId;
        if (search_term.length >= 4) {
            let searchServiceFlag = true;
            serviceSearchTermApi(search_term, serviceType, webLocationId, searchServiceFlag)
        }
    });
}
if ($(".warranty-screen input[name='serviceListQuery']").length) {
    $(".warranty-screen input[name='serviceListQuery']").keyup(function (this: any) {
        if (servieModal.enable) {
            $(".servicesListDropdown").empty();
            let checkModifier = window.location.href.includes('commercial');
            window.objOSGlobal.requestBodyValues.serviceName = 'Other Appliance Services';
            if (location.hostname.indexOf("nblytest") != -1 || location.hostname.indexOf("nblydev") != -1) {
                if (checkModifier) {
                    window.objOSGlobal.requestBodyValues.serviceSkuId = 7988;
                    window.objOSGlobal.requestBodyValues.ServiceId = '1';
                    window.objOSGlobal.requestBodyValues.ServiceSkuIds = [7988];
                } else {
                    window.objOSGlobal.requestBodyValues.serviceSkuId = 7989;
                    window.objOSGlobal.requestBodyValues.ServiceId = '2';
                    window.objOSGlobal.requestBodyValues.ServiceSkuIds = [7989];
                }
            } else {
                if (checkModifier) {
                    window.objOSGlobal.requestBodyValues.serviceSkuId = 7988;
                    window.objOSGlobal.requestBodyValues.ServiceId = '1';
                    window.objOSGlobal.requestBodyValues.ServiceSkuIds = [7988];
                } else {
                    window.objOSGlobal.requestBodyValues.serviceSkuId = 7989;
                    window.objOSGlobal.requestBodyValues.ServiceId = '2';
                    window.objOSGlobal.requestBodyValues.ServiceSkuIds = [7989];
                }
            }
        }
        var search_term: any = $(this).val();
        var serviceTypeName = window.objOSGlobal.requestBodyValues.serviceType;
        var serviceType = getServiceType(serviceTypeName);
        var webLocationId = window.objOSGlobal.requestBodyValues.WebLocationId ? window.objOSGlobal.requestBodyValues.WebLocationId : 18519;
        if (search_term.length >= 4) {
            let searchServiceFlag = false;
            serviceSearchTermApi(search_term, serviceType, webLocationId, searchServiceFlag)
        }
    });
}

$('.modal').on('hidden.bs.modal', function () {

    if (animation.modalFlag) {
        location.reload();
    }
});

$(document).on("click", '.header-left-sec .primary-btn[data-target="#js-schedulerDialog"]', function (e: any) {
    e.preventDefault();
    //EstimateForm();
    $('.scheduler-modal .modal-content').addClass('banner-wizard');
    if ($('#conceptCode').val() == 'MLY') {
        removeClassForUpdateFlow()
    }
    var webLoc = $('#weblocationId').val();
    if (webLoc) {
        $(".tab-pane.fieldset").removeClass("active");
        getStateFun();
        localBrandsSitesFlow();
    }
});



$('.change-zip').click(function (this: any) {
    $(this).parents('.service-search-screen').removeClass('active');
    $('.zip-entry-screen').addClass('active');
    $('p.call-details-text').addClass('d-none');
    $('.schedulerModalLabel').empty();
})
$('#inputZipCode').keypress(function (this: any, e: any) {
    if (this.value === "0000" && e.which == 48) {
        return false;
    }
})
$('.calendar-wrapper .next-btn').click(function () {
    slotIn.slotIndex = slotIn.slotIndex + 5;
    if (getWeekObj.getNumbersOfDays) {
        if (slotIn.slotIndex < getWeekObj.getNumbersOfDays) {
            //defaultEnableCal.enable = true;
            dateDisplay(slotIn.slotIndex);
            $('.calendar-wrapper .prev-btn').removeClass('hidden');
        } else {
            $('.calendar-wrapper .next-btn').addClass('hidden');
        }
    } else {
        $('.calendar-wrapper .prev-btn').removeClass('hidden');
        //defaultEnableCal.enable = true;
        dateDisplay(slotIn.slotIndex);
    }
    //nextBtnDisableFun();
    $('.calendar-inner-wrap .date-details-wrapper .date-details:first-child').addClass('active');
    var scheduleID = window.objOSGlobal.requestBodyValues.ScheduleID
    $("button[data-scheduleid ='" + scheduleID + "']").addClass('active');
});
$('.calendar-wrapper .prev-btn').click(function () {
    // $('.calendar-wrapper .next-btn').removeClass('disble-btn').prop('disabled', false);
    slotIn.slotIndex = Math.max(slotIn.slotIndex - 5, 0)
    if (Math.max(slotIn.slotIndex) == 0) {
        //defaultEnableCal.enable = true;
        dateDisplay(Math.max(slotIn.slotIndex));
        $('.calendar-wrapper .prev-btn').addClass('hidden');
    } else {
        $('.calendar-wrapper .next-btn').removeClass('hidden');
        //defaultEnableCal.enable = true;
        dateDisplay(Math.max(slotIn.slotIndex));
    }
    //nextBtnDisableFun();
    $('.calendar-inner-wrap .date-details-wrapper .date-details:first-child').addClass('active');
    var scheduleID = window.objOSGlobal.requestBodyValues.ScheduleID
    $("button[data-scheduleid ='" + scheduleID + "']").addClass('active');
});
$('.location-search input[name="locationSearch"]').keypress(function (this: any, e: any) {
    if (this.value === "0000" && e.which == 48) {
        return false;
    }
    if ((e.keyCode == 13)) { return false; } //// Add this to solve [BUG 53212]
});
$('#ContactForm input[name="inputZip"]').keypress(function (this: any, e: any) {
    if (this.value === "0000" && e.which == 48) {
        return false;
    }
});
$('.scheduler-modal .modal-dialog .modal-content').click(function () {
    $('.services-list-dd').hide();
})
$('.contact-information-screen .js-next-btn').click(async function () {
    var addressScr = $('#schedulerWizard .tab-content .tab-pane.active').next().hasClass('address-details-screen');
    var addressStep = $('#schedulerWizard .tab-content .tab-pane.active').next().attr('id');
    if (addressScr && addressStep) {
        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        /* Using test() method to search regexp in details
        it returns boolean value*/
        let isMobileDevice = regexp.test(details);
        isMobileDevice && await getStateFun();
    }
    contactInformationScreen();

});

$('.services-list-screen .js-next-btn').click(function () {
    mollyMaidServicesList();

});

$(".calendar-row").on("click", ".calendar-btn", function (this: any) {
    $('.date-details-wrapper .date-details').removeClass('active');
    $('.calendar-btn').removeClass('active-cal');
    $('.calendar-btn').removeClass('active');
    if ($(this).hasClass('disabled')) {
        return;
    }
    var displayText = $(this).attr('data-time');
    var schduleId = $(this).attr('data-scheduleid');
    schedulerState.selectedDate = $(this).attr('data-selectedDate');
    window.objOSGlobal.requestBodyValues.ScheduleID = schduleId;
    $(this).addClass('active-cal');
    $(this).addClass('active');
    var Preferredtime = $('.active-cal').html();
    sessionStorage.setItem('CTime', Preferredtime);
    $('.selected-date-txt').text(displayText);
    $('.selected-date').removeClass('d-none');
    $('.calendar-screen .next').removeClass('disble-btn').prop('disabled', false);
    let currenWrapId = $(this).parent().attr('id');
    $('.date-details-wrapper [data-index = ' + currenWrapId + ']').addClass('active');
    if(localStorage.getItem("selectedAboutHome") === "onetime"){
        let myDate = new Date(schedulerState.selectedDate);
        if(isWeekendSaturday(myDate)){
            let saturdayPrice = sessionStorage.getItem("estimateSaturdayPrice");
            saturdayPriceDisplay(saturdayPrice);
            $('.saturday-selected-text').removeClass('d-none');
        }else{
            let mollyEstimate = sessionStorage.getItem("mollyEstimate");
            saturdayPriceDisplay(mollyEstimate);
            $('.saturday-selected-text').addClass('d-none');
        }
    }
});
$(".date-details-wrapper").on("click", ".date-details", function (this: any) {
    // nextBtnDisableFun();
    $('.date-details-wrapper .date-details').removeClass('active');
    $(this).addClass('active');
    var datesIndexId = $(this).attr("data-index");
    $('.calendar-col').hide();
    if (window.brandData?.column_scheduler_layout || $("#brandName").val().toLowerCase() === "molly-maid"){
        $('#' + datesIndexId).css('display','flex');
    }
    else{
        $('#' + datesIndexId).show();
    }
    
});
$('.calendar-screen .js-next-btn').click(function () {
    window.objOSGlobal.paramsUI.selectedDate = schedulerState.selectedDate;
    $('.address-details-screen .js-next-btn').show();

})
$('.serviceSignIn').click(function (this: any, e: any) {
    e.preventDefault();
    var currentId = $(this).parent().parent().attr("id");
    if (currentId == "step3") {
        localStorage.setItem("step3", "personalinfo");
    }
    sessionStorage.setItem('objectGlobal', JSON.stringify(window.objOSGlobal.requestBodyValues));
    sessionStorage.setItem('paramUIValue', JSON.stringify(window.objOSGlobal.paramsUI));
    signIn();
    // msalManager.signInRedirect();
});

//Lead confimation 
$(window).on("load", async function () {
    let conceptID = $('#conceptCode').val();
    if (conceptID == 'PLG') {
         confirmationClickEvent()
    }
    else {
        if (conceptID != 'MRA') {
            $('.confirmation-screen .next').on('click', function (e: any) {
                e.preventDefault();
                confimationScreen();
            });
        }
    }
    if (window.location.href.indexOf("confirmation") > -1) {
        if (conceptID == 'PLG') {
            leadConfirmationCallApiPlg();
        } else {
            if ($('#conceptCode').val() === 'MRR') {
                mrrConfirmationTextColor();
            }
            leadConfirmationCall();
            if (sessionStorage.getItem('shortForm') ) {
                shortFormConfirmationText()
            }
        }

    }
    if (conceptID === 'PLG') {
        if (window.location.href.indexOf("contact-thankyou") > -1) {
            irvinrewardFormConfirmationPage();
        } 
    } 
  
});


//estimate booking api
$(window).on("load", function () {
    if ($(".contact-form")[0]) {
        if (!$(".thankyou-sec")[0]) {
            EstimateForm();
        }
    }
    if($("#brandName").val().toLowerCase() === 'mr-appliance' ){
        $('.contact-form .js-next-btn').on('click', function (e: any) {
            e.preventDefault();
            contactFormSubmit(e);
        })
    }
    else{
        $(document).on('click','.contact-form .js-next-btn',function (e: any) {
            e.preventDefault();
            contactFormSubmit(e);
        })
    }
    
    if ($(".thankyou-sec")[0] && !$("#ins_thankyou_page")[0]) {
        estimateFromConfirmationCall();
    }
    if ($('#conceptCode').val() == 'MLY') {
        if ($('.confirmation-page').hasClass('callback-appointment')) {
            thankYouPageConfirmation();
        }
    }
})

//Rename appropriately
function contactFormSubmit(e:any){
    if (!$("#insurance_thankyou_page")[0]) {
        if($('#estimateForm').valid()){
            setLeadOnly();
            let brands = $("#countryCode").val().toUpperCase() == 'CA' ? "/ca/en-ca/_assets/brands.json" : "/us/en-us/_assets/brands.json";
            $.getJSON(brands, async function (result: any) {
                if (result.length > 0) {
                    var items = $.grep(result, function (e: any) { return e.code === $('#conceptCode').val() });
                    window.objOSGlobal.requestBodyValues.brandJsonGlobalObj = items;
                    window.objOSGlobal.requestBodyValues.VendorId = items[0].vendorId ? items[0].vendorId : "";
                    window.objOSGlobal.requestBodyValues.VendorName = items[0].vendorName ? items[0].vendorName : "";
                    e.preventDefault();
                    estFormBookingApiCall();
                }
            })
        }
    } else {
        $('.vertical-contact-form .form-ctn').show();
        $('.insscheduler-modal').addClass('d-none');
    }
}

$(document).on('click', '.contact-right.mly-callBack .call-txt.callback-link', function (e: any) {
    if ($('#conceptCode').val() == 'MLY') {
        e.preventDefault();
        setLeadOnly();
        confimationScreen();
        return false;
    } else if ($("#brandName").val() === 'mr-handyman' || $("#brandName").val() === "my-handyman") {
        e.preventDefault();
        setLeadOnly();
        confimationScreen();
    }
});

export function nextBtnDisableFun() {
    $('.selected-date-txt').text("");
    $('.selected-date').addClass('d-none');
}
//MRH showing service modal on Go button click event
$(document).on('click', "#service_modal", async function () {
    if ($('#mrh_show_service_search_modal')[0]) {
        const postalCode: any = $('.banner-sec input[name="cta-ser-search"]').val();
        mrhServiceModal(postalCode);
    } else {
        removeBlurFromBody();
        callToServicePage();
    }
});

$("#mrh_modal_location_not_found .close").on('click', function () {
    $("#mrh_modal_location_not_found").addClass('d-none');
    $("#mrh_modal_location_not_found").removeClass('show');
    removeBlurFromBody();
});

$("#mrh_modal_location_found #modal_close").on('click', function () {
    $("#mrh_modal_location_found").addClass('d-none');
    $("#mrh_modal_location_found").removeClass('show');
    removeBlurFromBody();
});

$("#mrh_modal_services_not_given #modal_close").on('click', function () {
    $("#mrh_modal_services_not_given").addClass('d-none');
    $("#mrh_modal_services_not_given").removeClass('show');
    removeBlurFromBody();
});

$('#mrh_modal_location_found .req-btn .primary-btn').click(async function (e: any) {
    e.preventDefault();
    $("#mrh_modal_location_found").addClass('d-none');
    $("#mrh_modal_location_found").removeClass('show');
    callToServicePage();
})

async function callToServicePage() {
    startLoader();
    let regexp: any = /android|iphone|kindle|ipad/i;
    let Devicedetails: any = navigator.userAgent;
    let isMobileDevice = regexp.test(Devicedetails);
    isMobileDevice && await getStateFun();
    removeBlurFromBody();
    $('#js-schedulerDialog').modal('show');
    serviceEntryBtn();
}

function removeBlurFromBody() {
    if ($('.modal-backdrop.fade.show')[0]) {
        $('.modal-backdrop.fade.show').remove();
    }
    $('body.mr-handyman.wf-loading').css({ overflowY: 'auto' });
}
$(document).on("click", ".modal .close", function () {
    if ($("#conceptCode").val() === 'ASV') {
        $('.modal-header-two').css('display', 'none');
        $('.modal-header-one').removeClass('d-none');
        $('.progress, .progressbar').css('display', 'none')
    }
})


export function isWeekendSaturday(date: any,locale="en-US")
{
    return new Intl.Locale(locale)?.weekInfo?.weekend.includes(date.getUTCDay()||7);
}

export function saturdayPriceDisplay(pricing:any){
    sessionStorage.setItem("mollymaidprice",pricing);
    $(".saturday-selected-price").text(pricing);
}