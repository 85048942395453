import { LeadConfirmation } from "../../services/LeadConfirmation";
import { $ } from "../../third-party-imports";
import { GetReferenceDetails, checkZipLocalSite, mrhs3upload } from "../../util";
import { changeZipCodeInContactScreenFlow } from "../common-os/change-zip-code-contact";
import { preferredMethodOfContact, validTextMethodChk } from "../common-os/os-flow-optimize";

export function fsp_OptOsFlow()
{   
    changeZipCodeInContactScreenFlow();

    $(".calendar-row").on("click", ".calendar-btn", function (this: any){
        $(".dt-error-calender").remove();
    });    

    if ($("#conceptCode").val() == "FSP") {
        $(".js-next-btn").click(async function (this: any) {
        
            let nextId = $(this).parents(".tab-pane").next().attr("id");
            if (nextId == "step3") {
                var displayDate = $(".selected-date-txt").text();
                if (displayDate) {
                    let postalCode = localStorage.getItem("zipcode");
                    $('#contactusformfsp input[name="inputZipCode"]').val(
                        postalCode
                    );
                    $('[href="#' + nextId + '"]').tab("show");
                } else {
                    $('.dt-error-calender').remove();
                    if(!window.objOSGlobal.requestBodyValues.brandJsonGlobalObj[0].opus_call_back_comp_enabled){
                    $(".progressbar").after('<div class="dt-error-calender body-text-sm text-center">Please select your preferred date and time. </div>');
                    } else {
                    $(".progressbar").after('<div class="dt-error-calender body-text-sm text-center">Please select your preferred date and time or choose to get a call back. </div>');
                    }
                    $('.modal').animate({
                        'scrollTop' : $("#schedulerWizard").offset().top
                    });
                }
            } else if (nextId == "step4") {
                $(".contact-information-screen").addClass("active");
                fspContactInformation();
                preferredMethodOfContact();
                if ($("#contactusformfsp").valid() && validTextMethodChk()) {
                    var changeZip = sessionStorage.getItem("changeZip");
                    if (changeZip) {
                        checkZipLocalSite(nextId);
                        GetReferenceDetails();
                        return;
                    }
                    GetReferenceDetails();
                    $('[href="#' + nextId + '"]').tab("show");
                }
            } else if (nextId == "hdnSD_NoResultsMsg") {
                if ($("#referenceDetails").attr("required")?.length) {
                    if ($("#serviceDetails").valid()) {
                            fspServiceDetails();
                            if ($("#file").length > 0) {
                                mrhs3upload(function () {
                                    LeadConfirmation();
                                });
                            } else {
                                LeadConfirmation();
                            }
                      
                    } else {
                        return false;
                    }
                } else {
                        fspServiceDetails();
                        if ($("#file").length > 0) {
                            mrhs3upload(function () {
                                LeadConfirmation();
                            });
                        } else {
                            LeadConfirmation();
                        }
                   
                }
            }
        
    });

     //Previous button click code
    $(".js-previous-btn").click(function (this: any){
       if($('#conceptCode').val() == "FSP") {
        let prevId = $(this).parents('.tab-pane').prev().attr("id");
        if(window.objOSGlobal.requestBodyValues.IsLeadOnly){
            if (prevId =="step2") {
                $('.scheduler-modal .modal-content').addClass('banner-wizard');
                $('.tab-pane.fieldset').removeClass('active');
                $('.zip-entry-screen').addClass('active');
                $('.modal-header-two').hide();
                $('.modal-header-one').removeClass('d-none');
                $('.progress, .progressbar').hide();
                $('.schedulerModalLabel').addClass('d-none');
                $('.schedulerModalLabel').empty();
                $('.dt-error-calender').remove();
            }else if (prevId =="step3"){
                $('[href="#' + prevId + '"]').tab('show');
            }
          }else {
                if(prevId =="step1") {
                $('.scheduler-modal .modal-content').addClass('banner-wizard');
                $('.tab-pane.fieldset').removeClass('active');
                $('.zip-entry-screen').addClass('active');
                $('.modal-header-two').hide();
                $('.modal-header-one').removeClass('d-none');
                $('.progress, .progressbar').hide();
                $('.schedulerModalLabel').addClass('d-none');
                $('.schedulerModalLabel').empty();
                $('.dt-error-calender').remove();
                }else {
                $('[href="#' + prevId + '"]').tab('show');
                }
          }
        }
    });

    }
    
    
    //Progress bar progress code
    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e: any)
    {
        //update progress
        let isLead = window.objOSGlobal.requestBodyValues.IsLeadOnly;
        let checker: any = false;
        if (isLead)
        {
            checker = true;
        }
        let step = $(e.target).data('step');
        let percent: any = ((parseInt(step) / 3) * 100).toFixed(2);
        switch (step)
        {
            case 1:
                percent = 0;
                $('.progress-bar').css({ width: percent + '%' });
                break;
            case 2:
                percent = 5.2;
                $('.progress-bar').css({ width: percent + '%' });
                break;
            case 3:
                percent = checker ? 5.2 : 53;
                $('.progress-bar').css({ width: percent + '%' });
                break;
            case 4:
                percent = 100;
                $('.progress-bar').css({ width: percent + '%' });
                break;
            default:
                $(".progress").addClass("d-none");
                $(".progressbar").addClass("d-none");
                $(".modal-header-two").addClass("d-none");
                $(".modal-header-one").addClass("d-none");
                break;
        }
    });
}

export function fspContactInformation(){
    let firsName = $(".fsp-contact-info-screen input[name='inputFirstName']").val();
    let lastName = $(".fsp-contact-info-screen input[name='inputLastName']").val();
    let email = $(".fsp-contact-info-screen input[name='email']").val();
    let phoneNumber = $(".fsp-contact-info-screen input[name='inputPhnNumber']").val();
    let address = $(".fsp-contact-info-screen input[name='inputAddress']").val();
    let apartment = $(".fsp-contact-info-screen input[name='inputAddress2']").val();
    let city = $(".fsp-contact-info-screen input[name='inputcity']").val();
    let zipcode  = $(".fsp-contact-info-screen input[name='inputZipCode']").val();
    let state  = $(".fsp-contact-info-screen select[name='inputstate']").val();
    window.objOSGlobal.requestBodyValues.FirstName = firsName;
    window.objOSGlobal.requestBodyValues.LastName = lastName;
    window.objOSGlobal.requestBodyValues.Email = email.trim();
    window.objOSGlobal.requestBodyValues.Phone = phoneNumber;
    window.objOSGlobal.requestBodyValues.Address = address;
    window.objOSGlobal.requestBodyValues.Address2 = apartment;
    window.objOSGlobal.requestBodyValues.City = city;
    window.objOSGlobal.requestBodyValues.State = state;
    window.objOSGlobal.requestBodyValues.PostalCode = zipcode;
    var addressParamsVal:any = address + "&" + apartment + "&" + city + "&" + state + "&" + window.objOSGlobal.requestBodyValues.PostalCode;
    return addressParamsVal
}

function fspServiceDetails() {
    let selectedItemObj:any;
    let nullValue:any = null;
    let referType = $('#referenceDetails option:selected').text();
    let syncSourceId = $('#referenceDetails option:selected').val();
    if(referType !=''){
        if(window.objOSGlobal.leadSourceObj){
            selectedItemObj = window.objOSGlobal.leadSourceObj?.find((item:any)=> item.leadSourceName === referType);
        }
        if(!selectedItemObj?.referralTypeId) {
            window.objOSGlobal.requestBodyValues.NeighborlyLeadSourceId = syncSourceId?.toString();
            window.objOSGlobal.requestBodyValues.ReferralTypeId = nullValue;
            window.objOSGlobal.requestBodyValues.ReferralType = nullValue;
        } else {
            window.objOSGlobal.requestBodyValues.NeighborlyLeadSourceId = nullValue;
            window.objOSGlobal.requestBodyValues.ReferralTypeId = selectedItemObj?.referralTypeId;
            window.objOSGlobal.requestBodyValues.ReferralType = selectedItemObj?.leadSourceName;
        }
    }

}


