import {$} from "../third-party-imports";
import { croConfirmation } from "./cro-os-flow/cro-mly-one-time-recurring";
export function leadConfirmationCall(){
    var MlyHomeDetails = sessionStorage.getItem("MlyHomeDetails");
    var firstName = sessionStorage.getItem("CFirstName");
    var lastName = sessionStorage.getItem("CLastName");
    var jobDate = sessionStorage.getItem("CJobDate");
    var Time = sessionStorage.getItem("CTime");
    var serviceRequest = sessionStorage.getItem("CServiceRequest") ?? '';
    var email = sessionStorage.getItem("CEmail");
    var phone = sessionStorage.getItem("CPhone");
    var street = sessionStorage.getItem("CAddress");
    var street2 = sessionStorage.getItem("CAddress2");
    var city = sessionStorage.getItem("CCity");
    var state = sessionStorage.getItem("CState");
    var pincode = sessionStorage.getItem("CPostalCode");
    var modifier = sessionStorage.getItem('ServiceModifierValue');
    var ServiceType = sessionStorage.getItem('CServiceType');
    var BusinessName = sessionStorage.getItem('CBusinessName');
    var PreferredCommunicationText = sessionStorage.getItem('PreferredCommunicationText');
    var guyServiceType = sessionStorage.getItem('guyServiceType');
    var guyServiceRequest = sessionStorage.getItem('guyServiceRequest');
    var fullname = firstName + " " + lastName;
    var addressObj = street2 != "" ? street + ' ' + street2 : street;
    var fulladdress = addressObj + '<br/>' + city + ', ' + state + ' ' + pincode;
    var estimateMolly = sessionStorage.getItem('mollyEstimate');
    var mraDiagnoseFee = sessionStorage.getItem('mra-diagnose-fee');
    var dt = $('<dt>'); var dt1 = $('<dt>'); var dt2 = $('<dt>'); var dt3 = $('<dt>'); var dt4 = $('<dt>'); var dt5 = $('<dt>'); var dt6 = $('<dt>'); var dt7 = $('<dt>'); var dt8 = $('<dt>'); var dt9 = $('<dt>'); var dt10 = $('<dt>'); var dt11 = $('<dt>');var dt12 = $('<dt>');var dt13 = $('<dt>');var dt14 = $('<dt>');
    var dd = $('<dd>'); var dd1 = $('<dd>'); var dd2 = $('<dd>'); var dd3 = $('<dd>'); var dd4 = $('<dd>'); var dd5 = $('<dd>'); var dd6 = $('<dd>'); var dd7 = $('<dd>'); var dd8 = $('<dd>'); var dd9 = $('<dd>'); var dd10 = $('<dd>'); var dd11 = $('<dd>');var dd12 = $('<dd>');var dd13 = $('<dd>');var dd14 = $('<dd>');
    if (sessionStorage.getItem("CLeadOnly") != 'true') {
        $(dt).html('Date:');
        $(dd).html(jobDate);
        $(dt1).html('Time:');
        $(dd1).html(Time);
        if($("#confirmation-title-schedule").val() != null && $("#confirmation-title-schedule").val() != "")
        $(".confirmation-data .primary-title").text($("#confirmation-title-schedule").val() as any);
        else
        $(".confirmation-data .primary-title").addClass("d-none");
        if($("#confirmation-subtext-schedule").val() != null && $("#confirmation-subtext-schedule").val() != "")
        $(".confirmation-data .sub-title").text($("#confirmation-subtext-schedule").val() as any);
        else
        $(".confirmation-data .sub-title").addClass("d-none");
    }
    else{
        if($("#confirmation-title-lead").val() != null && $("#confirmation-title-lead").val() != "")
        $(".confirmation-data .primary-title").text($("#confirmation-title-lead").val() as any);
        else
        $(".confirmation-data .primary-title").addClass("d-none");
        if($("#confirmation-subtext-lead").val() != null && $("#confirmation-subtext-lead").val() != "")
        $(".confirmation-data .sub-title").text($("#confirmation-subtext-lead").val() as any);
        else
        $(".confirmation-data .sub-title").addClass("d-none");
        
    }
    if($('#conceptCode').val() == "MLY" || $('#conceptCode').val()=='FSP' || sessionStorage.getItem("ServiceModalEnable")) {
        if($('#conceptCode').val() != "MLY" && $('#conceptCode').val() != "GUY" && sessionStorage.getItem("CLeadOnly") == 'true'){
            $(".confirmation-page .confirmation-col.appointment").addClass("d-none");
        }
        else{
            $(".confirmation-page .confirmation-col.appointment").removeClass("d-none");
        }
    }else{
        $(dt2).html('Service Request:');
        $(dd2).html(serviceRequest);
    }
    if($('#conceptCode').val() == "MLY"){
        if(localStorage.getItem("selectedAboutHome") == "onetime"){
            $(dt7).html('Frequency:');
            $(dd7).html('One-time Cleaning');
        }
        else{
            $(dt7).html('Frequency:');
            $(dd7).html('Recurring Cleaning');
        }
        if(estimateMolly){
            $(dt9).html('Estimate:');
            $(dd9).html(estimateMolly);
        }
        $(dd7).attr('id','cleaning-type');
    }
    if($('#conceptCode').val() == "MLY"){
        if(modifier =='Commercial'){
            $(dt8).html('Office Details:');
            $(dd8).html(MlyHomeDetails);
        }else{
            $(dt8).html('Home Details:');
            $(dd8).html(MlyHomeDetails);
        }
        
    }
    $(dt3).html('Name:');
    $(dd3).html(fullname);
    $(dt4).html('Email:');
    $(dd4).html(email);
    $(dt5).html('Phone:');
    $(dd5).html(phone);
    try{
    if(window.brandData.add_preffered_method_of_contact === true){
        if(PreferredCommunicationText !=""){
            $(dt14).html('Preferred Method of Contact :');
            $(dd14).html(PreferredCommunicationText);
        }
    }
} catch(error) {
    console.log("not found add_preffered_method_of_contact", error)
}
    $(dt6).html('Service Address:');
    $(dd6).html(fulladdress);
    $(dt10).html('Service Type:');
    $(dd10).html(ServiceType);
    $(dt11).html('Business Name:');
    $(dd11).html(BusinessName);
    if($('#conceptCode').val() == "MRA"){
        $(dt2).html('Service Request:');
        $(dd2).html(serviceRequest);         
        //mra diagnose fee on confirmation page
        if(mraDiagnoseFee !=""){
            $(dt12).html('Diagnostic Fee:');
            $(dd12).html('Our in-home service professionals will look at your appliance, diagnose the problem, and give you an exact quote. If you choose not to have the repair done, you pay only the <strong>$' + mraDiagnoseFee + ' diagnostic fee</strong>.');
        } 
    }
    if($('#conceptCode').val() == "GUY"){
        $(dt2).html('Service Type:');
        $(dd2).html(guyServiceType);
        $(dt13).html('Service Request:');
        $(dd13).html(guyServiceRequest);
    }
    $('.confirmation-data .appointment .text-sm').append(dt).append(dd);
    $('.confirmation-data .appointment .text-sm').append(dt1).append(dd1);
    $('.confirmation-data .appointment .text-sm').append(dt2).append(dd2);
    $('.confirmation-data .appointment .text-sm').append(dt13).append(dd13);
    $('.confirmation-data .appointment .text-sm').append(dt7).append(dd7);
    $('.confirmation-data .appointment .text-sm').append(dt8).append(dd8);
    $('.confirmation-data .contact .text-sm').append(dt3).append(dd3);
    $('.confirmation-data .contact .text-sm').append(dt4).append(dd4);
    $('.confirmation-data .contact .text-sm').append(dt5).append(dd5);
    $('.confirmation-data .contact .text-sm').append(dt14).append(dd14);
    $('.confirmation-data .contact .text-sm').append(dt6).append(dd6);
    $('.confirmation-data .appointment .text-sm').append(dt9).append(dd9);
    if(localStorage.getItem("estIsLeadOnly") === 'false'){
        $('.confirmation-data .appointment .text-sm').append(dt12).append(dd12);
    } else {
        $('.confirmation-data .contact .text-sm').append(dt12).append(dd12);
    }
    if($("#brandName").val() === 'mr-handyman' || $("#brandName").val() === 'my-handyman') {
        if(sessionStorage.getItem("CLeadOnly") != 'true') {
          if(ServiceType) {
              $('.confirmation-data .appointment .text-sm').append(dt10).append(dd10);
              }
              if(BusinessName) {
              $('.confirmation-data .appointment .text-sm').append(dt11).append(dd11); 
              }
        }else {
          if(ServiceType) {
              $('.confirmation-data .contact .text-sm').append(dt10).append(dd10);
          }
          if(BusinessName) {
              $('.confirmation-data .contact .text-sm').append(dt11).append(dd11); 
          }
        }
      }

    //USER STORY 76501
    if($('#conceptCode').val() == "MLY") {
        var frequency = localStorage.getItem("selectedAboutHome");
        var brands = window.objOSGlobal.requestBodyValues.Country == 'CA' ? "/ca/en-ca/_assets/brands.json" : "/us/en-us/_assets/brands.json";
        
        $.getJSON(brands, function (result:any) {
            if (result.length > 0) {
                var items = $.grep(result, function (e:any) { return e.code === $('#conceptCode').val() });
                
                if(items.length) {
                    
                    var title_Lead = frequency == "recurring" ? items[0].confirmation_title_lead_recurring : items[0].confirmation_title_lead_onetime;
                    var subtitle_Lead = frequency == "recurring" ? items[0].confirmation_subtext_lead_recurring : items[0].confirmation_subtext_lead_onetime;
                    var title_schedule = frequency == "recurring" ? items[0].confirmation_title_schedule_recurring : items[0].confirmation_title_schedule_onetime;
                    var subtitle_schedule = frequency == "recurring" ? items[0].confirmation_subtext_schedule_recurring : items[0].confirmation_subtext_schedule_onetime;

                    if (sessionStorage.getItem("CLeadOnly") != 'true') {
                        $('.confirmation-banner .primary-title').text(title_schedule);
                        $('.confirmation-banner .sub-title').text(subtitle_schedule);
                    }
                    else{
                        $('.confirmation-banner .primary-title').text(title_Lead);
                        $('.confirmation-banner .sub-title').text(subtitle_Lead);
                    }
                    croConfirmation();
                }
            }
        });
        
    }
}