import { $ } from "../third-party-imports"

$(function ()
{
    $(".print-btn").click(function (this:any, e:any)
    {
        var contents = $('<div>').append($(this).parent().closest("section").clone()).html();
        var urlLink = window.location.href.split('/');
        var isWebSite = urlLink[3] === "ca" ? "ca" : "us";
        var brandCss = isWebSite == 'ca' ? "/us/en-us/brand/_assets/css/"+urlLink[4]+".css" : "/us/en-us/brand/_assets/css/"+urlLink[3]+".css"; 
        var frame1 = $<HTMLIFrameElement>('<iframe />');
        frame1[0].name = "frame1";
        frame1.css({ "position": "absolute", "width": "100%", "size": "A4 landscape", "margin": "5%" });
       
        $("body").append(frame1);
        var frameDoc = frame1[0].contentWindow ? frame1[0].contentWindow : frame1[0].contentDocument["document"] ? frame1[0].contentDocument["document"] : frame1[0].contentDocument;
        frameDoc.document.open();
        // var is_chrome = Boolean(frameDoc.chrome);    
        frameDoc.document.write(`<html><head><title></title>`);  //Create a new HTML document.  
        //Append the external CSS file.
        frameDoc.document.write(`
        <link rel="preload" href="/us/en-us/brand/_assets/css/bootstrap.min.css" as="style">
        <link rel="preload" href="/us/en-us/brand/_assets/css/shared.css" as="style">
        <link rel="preload" href="${brandCss}" as="style">
    
        <link href="/us/en-us/brand/_assets/css/bootstrap.min.css" rel="stylesheet" type="text/css" media="print" />
        <link href="/us/en-us/brand/_assets/css/shared.css" rel="stylesheet" type="text/css" media="print" />
        <link href="${brandCss}" rel="stylesheet" type="text/css" media="print" />
        <style>
        .btn-group li {
            display: none !important;
        }
        .btn-group li:first-child {
            display: block !important;
        }  
        .btn-group, .btn-group-vertical {
            display: block !important;
        }  
        .service-coupon-section {
            padding: 7mm;
        }
        /*--------------------For Print Default Setting--------------------*/
        @page {
            margin:0mm;
          }
          * {
            -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
            color-adjust: exact !important;                 /*Firefox*/
        }
        @media print { 
            @-moz-document url-prefix() {
        
             .btn-group li {
                display: none !important;
             }
             .btn-group li:first-child {
                display: block !important;
             }
                }
        }
        </style>
        `);
        frameDoc.document.write('</head>');
        frameDoc.document.write('<body class="wf-loading">');    
        frameDoc.document.write(contents); //Append the DIV contents.
        frameDoc.document.write('</body></html>');
    
        setTimeout(function ()
            {
                frameDoc.document.close(); // necessary for IE >= 10
                frameDoc.focus(); // necessary for IE >= 10
                frameDoc.print();
                frame1.remove();
                frameDoc.document.close();
            }, 250);
    
    });
});