export function dynamicGetMenu(url:any,successHandler:any){
    $.ajax({
        url: url,
        dataType: 'json',
        type: 'GET',
        success: successHandler, 
        error: function (){
        }
    });
}
