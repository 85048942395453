export function brandJsonConfimationScreen(bookingApiCall:any){
    let brands = window.objOSGlobal.requestBodyValues.Country == 'CA' ? "/ca/en-ca/_assets/brands.json" : "/us/en-us/_assets/brands.json";
    $.getJSON(brands, function (result: any){
        if (result.length > 0){
            var items = $.grep(result, function (e: any) { return e.code === $('#conceptCode').val() });
            window.objOSGlobal.requestBodyValues.brandJsonGlobalObj = items;
            window.objOSGlobal.requestBodyValues.VendorId = items[0].vendorId ? items[0].vendorId : "";
            window.objOSGlobal.requestBodyValues.VendorName = items[0].vendorName ? items[0].vendorName : "";
            localStorage.setItem("isTrailingSlash", items[0].includeTrailingSlash);
            bookingApiCall();
        }
    });
}